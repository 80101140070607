import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import TeacherVideoPlayer from "./TeacherVideoPlayer";
import AnswerRender from "./AnswerRender";

const TeacherConversationFocusedMessage = ({ message }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  const [playable_links, setPlayableLinks] = useState([]);

  useEffect(() => {
    const split_text = message.content.split(/(https?:\/\/[^\s]+)/g);
    const duplicated_array = split_text.concat(
      split_text.forEach((element, index, theArray) => {
        theArray[index] = element.replace("%5F", "_");
      })
    );
    const ducplicated_links_array = duplicated_array.filter((e) =>
      ReactPlayer.canPlay(e)
    );
    //console.log("can play : ", [...new Set(ducplicated_links_array)]);
    setPlayableLinks([...new Set(ducplicated_links_array)]);
  }, [message]);

  return (
    <div
      key={message.external_id}
      className={`${
        message.msg_type === "question"
          ? "ml-auto text-white rounded-tr-none"
          : "mr-auto rounded-tl-none"
      } px-3 py-2 rounded-md whitespace-pre-line break-words`}
      style={{
        backgroundColor:
          message.msg_type === "question"
            ? themeColors.primary.base
            : themeColors.answer_background.base,
        width: playable_links.length > 0 ? "80%" : "fit-content",
      }}
    >
      {message.msg_type === "question" ? (
        <AnswerRender text={message.content}/>
      ) : (
        <AnswerRender
          html={
            message.sent_at > new Date(2022, 2, 17, 9, 45, 0)
              ? message.content
              : null
          }
          text={
            message.sent_at < new Date(2022, 2, 17, 9, 45, 0)
              ? message.content
              : null
          }
        />
      )}
      {message.msg_type !== "question" && playable_links.length > 0 && (
        <div className="flex flex-col gap-2">
          {playable_links.map((video, i) => (
            <TeacherVideoPlayer url={video} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TeacherConversationFocusedMessage;
