import { useRef, useContext } from "react";
import PaneContent from "../components/PaneContent";
import InputAndHints from "./components/InputAndHints/InputAndHints";
import Onboarding from "./components/Onboarding";
import ChatMessages from "./components/ChatMessages/ChatMessages";
import { useSelector } from "react-redux";
import HintsV2 from "./components/InputAndHints/components/HintsV2";
import { ThemeContext } from "../../ThemeProvider";

import { CSSTransition } from "react-transition-group";

const ChatPane = () => {
  const { t } = useContext(ThemeContext);
  const chatInputRef = useRef(null);
  const focusOnChatInput = () => {
    chatInputRef.current.focus();
  };

  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );

  const ab_test_send_blocked = useSelector(
    (state) => state.app.ab_test.send_blocked
  );

  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );
  const display_alert_msg = useSelector(
    (state) => state.app.ab_test.display_alert_msg
  );
  return (
    <>
      <PaneContent>
        {chat_data_array.length > 0 ? (
          <ChatMessages focusOnChatInput={focusOnChatInput} />
        ) : (
          <Onboarding />
        )}
        {bob_input_value.length > 1 && ab_test_send_blocked && <HintsV2 />}

        <div className="absolute flex items-center w-full bottom-0  text-white p-1">
          <CSSTransition
            in={display_alert_msg}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <div className="flex justify-center text-center mx-auto w-2/3 bg-black opacity-80 rounded-md p-2">
              {t("ab-test-alert")}
            </div>
          </CSSTransition>
        </div>
      </PaneContent>
      <InputAndHints chatInputRef={chatInputRef} />
    </>
  );
};

export default ChatPane;
