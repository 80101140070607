export const new2oldIntent = (intent) => {
  switch (intent) {
    case "course_related/answer_found":
      return "course_related_question";
    case "course_related/answer_not_found":
      return "course_related_question";
    default:
      const oldIntent = intent.replace("social/", "");
      return oldIntent;
  }
};
