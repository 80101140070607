export const defineABTestPopulation = (user_id, client_id) => {
  const alpha_num = user_id.replace(/[^a-z0-9]/gi, "");
  const first_alpha_num = alpha_num.substring(2, 3);
  const num =
    parseInt(first_alpha_num) ||
    first_alpha_num.toLowerCase().charCodeAt(0) - 97 + 1;

  //console.log("user_id to num", user_id, num, num % 2 ? "pair" : "impair");
  if (client_id === "adLz6AKrQw83EC" || client_id === "owUMSEs82weY8l" || client_id === "ea5483b7af4ad0") {
    return true;
  } else return false;
};
