import { BsArrowsAngleContract, BsArrowsAngleExpand } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { XsetExpandedPane } from "../../../../appSlice";
import { useSelector } from "react-redux";

const ExpandButton = () => {
  const dispatch = useDispatch();
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);

  if (expanded_pane) {
    return (
      <div
        className="flex font-semibold justify-end mt-2 items-center"
        onClick={() => dispatch(XsetExpandedPane(!expanded_pane))}
      >
        <BsArrowsAngleContract />
      </div>
    );
  }
  return (
    <div
      className="flex font-semibold  justify-end mt-2 items-center"
      onClick={() => dispatch(XsetExpandedPane(!expanded_pane))}
    >
      <BsArrowsAngleExpand />
    </div>
  );
};

export default ExpandButton;
