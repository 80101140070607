import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import BlockMath from './BlockMath';
import BlockMathViewerOnly from './BlockMathViewer';

export const BlockMathEditor = Node.create({
  name: 'block-math',  
  inline: true,
  group: "inline",
  selectable: false,

  atom: true,

  addAttributes() {
    return {
      math: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'block-math',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['block-math', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockMath);
  },
})

export const BlockMathViewer = Node.create({
  name: 'block-math',  
  inline: true,
  group: "inline",
  selectable: false,

  atom: true,

  addAttributes() {
    return {
      math: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'block-math',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['block-math', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockMathViewerOnly);
  },
})