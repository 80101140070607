import React, { useState, useRef, useEffect } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";
import { Input } from "antd";

import { useOnClickOutside } from "../../../libs/clickOutside";

const { TextArea } = Input;

const BlockMath = (props) => {
  const [math, setMath] = useState("");
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    let text;
    if (typeof props.node.attrs.math == "number") {
      text = props.node.attrs.math.toString();
    } else if (typeof props.node.attrs.math == "string") {
      text = props.node.attrs.math;
    } else {
      text = "";
    }
    setMath(text);
  }, [props.node.attrs.math]);

  const ref = useRef(null);

  const handleClickInside = () => {
    setFocus(true);
  };

  const handleClickOutside = () => {
    setFocus(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleChange = (e) => {
    props.updateAttributes({ math: e.target.value });
  };

  const handleEscapeKey = (e) => {
    if (e.key === "Escape") {
      setFocus(false);
    }
  };

  return (
    <NodeViewWrapper
      ref={ref}
      className={
        `relative bg-transparent rounded-sm h-max border-b border-gray-50 `
      }
      onClick={handleClickInside}
      onKeyDown={handleEscapeKey}
    >
      {focus && (
        <div
          className="
        transition absolute -top-2 inset-x-0 m-auto w-2/3 rounded-md shadow-lg border border-gray-300 bg-white z-30 transform -translate-y-full
        focus-within:ring-2 focus-within:border-orange-600 focus-within:ring-orange-300
        "
        >
          <TextArea
            autoFocus
            onChange={handleChange}
            onBlur={() => setFocus(false)}
            placeholder={"Enter an equation"}
            value={props.node.attrs.math}
            className="block h-full border-none resize-none outline-none shadow-none rounded-md py-2 items-center w-full"
            autoSize
          />
        </div>
      )}
      <TeX
        className="bg-transparent bg-opacity-0"
        math={math !== "" ? math : "i=\\sqrt{-1}"}
        block
      />
    </NodeViewWrapper>
  );
};

export default BlockMath;
