import { useTransition, animated, config } from "react-spring";
import { HiOutlineX } from "react-icons/hi";
import { LAYOUT, PANES } from "../../constants/constants";
import { useContext } from "react";
import { ThemeContext } from "../ThemeProvider";
import { useSelector, useDispatch } from "react-redux";
import { XsetDisableConversationStarter, XhandleIconButtonClick } from "../../appSlice";

const ConversationStarter = () => {
  const { t } = useContext(ThemeContext);

  const dispatch = useDispatch()

  const bob_position = useSelector((state)=>state.app.general.bob_position)
  const conversation_starter_visible = useSelector((state)=>state.app.general.conversation_starter_visible)
  const disable_conversation_starter = useSelector((state)=> state.app.general.disable_conversation_starter)
  const exam_mode = useSelector((state)=>state.app.general.exam_mode)

  const transitions = useTransition(conversation_starter_visible, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    unique: true,
    config: config.stiff,
  });

  if (disable_conversation_starter || !conversation_starter_visible) return <></>;

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className={`flex items-center z-50 `}
          key={key}
          style={{ props, ...{ marginBottom: "3.5rem" } }}
        >
          <div
            className={`flex items-end ${
              bob_position === LAYOUT.BOB_POSITION.LEFT
                ? "flex-row-reverse "
                : ""
            }`}
          >
            <div
              style={{
                filter: "drop-shadow(0 0 0.2rem gray)",
                minWidth: "250px",
              }}
              className={`bg-white rounded-3xl pr-10 p-4 m-2 flex items-center justify-center relative ${
                bob_position === LAYOUT.BOB_POSITION.LEFT ? `ml-0 ` : ``
              } 
              ${bob_position === LAYOUT.BOB_POSITION.RIGHT ? `mr-0 ` : ``}`}
            >
              <div className='cursor-pointer whitespace-pre-line' onClick={() => dispatch(XhandleIconButtonClick({icon_pane:PANES.CHAT}))}>
              {exam_mode ? t("exam-mode") : t("conversation-starter")}
              </div>
              
              <div
                className="absolute top-1 right-1 text-gray-400 p-3 cursor-pointer"
                onClick={() => {
                  dispatch(XsetDisableConversationStarter(true));
                }}
              >
                <HiOutlineX />
              </div>
            </div>

            <div className="flex flex-col">
              {bob_position === LAYOUT.BOB_POSITION.RIGHT && (
                <div
                  className="h-0 w-0 mb-8 z-50"
                  style={{
                    borderTop: "2px solid transparent",
                    borderBottom: "7px solid transparent",
                    borderLeft: "7px solid white",
                  }}
                />
              )}
              {bob_position === LAYOUT.BOB_POSITION.LEFT && (
                <div
                  className="h-0 w-0 mb-8 z-50"
                  style={{
                    borderTop: "2px solid transparent",
                    borderBottom: "7px solid transparent",
                    borderRight: "7px solid white",
                  }}
                />
              )}
            </div>
          </div>
        </animated.div>
      )
  );
};

export default ConversationStarter;
