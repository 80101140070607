import React, { useState, useRef, useEffect } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";

const InlineMath = (props) => {
  const [math, setMath] = useState("");

  useEffect(() => {
    let text;
    if (typeof props.node.attrs.math == "number") {
      text = props.node.attrs.math.toString();
    } else if (typeof props.node.attrs.math == "string") {
      text = props.node.attrs.math;
    } else {
      text = "";
    }
    setMath(text);
  }, [props.node.attrs.math]);

  return (
    <NodeViewWrapper
      className={`inline relative bg-transparent rounded-sm h-max`}
    >
      <TeX
        className="bg-transparent bg-opacity-0"
        math={math !== "" ? math : "i=\\sqrt{-1}"}
      />
    </NodeViewWrapper>
  );
};

export default InlineMath;
