import gql from "graphql-tag";

export const POST_HINT = gql`
  query PostHint($input: input) {
    postHint(input: $input)
      @rest(endpoint: "hints", path: "", type: "Questions", method: "POST") {
      conversationID
      hints
      timestamp
    }
  }
`;

export const CREATE_LOG_EVENT = gql`
  mutation CreateLogEvent($input: logInput) {
    createLogEvent(input: $logInput)
      @rest(
        endpoint: "logs"
        path: "/create_log_event"
        type: "Logs"
        method: "POST"
      ) {
      item
    }
  }
`;

//GQL

export const GQL_GET_USER_STUDENT_METRICS = gql`
  query GetUserStudentMetrics(
    $external_user_id: String!
    $external_course_id: String!
  ) {
    getUserStudentMetrics(
      external_user_id: $external_user_id
      external_course_id: $external_course_id
    ) {
      unreadMessages
      evaluationMode
    }
  }
`;

export const GQL_CHECK_EXTERNAL_USER_ID = gql`
  mutation CheckOrCreateExternalUserId(
    $external_user_id: String!
    $chapter_id: String!
  ) {
    checkOrCreateExternalUserId(
      external_user_id: $external_user_id
      external_chapter_id: $chapter_id
    )
  }
`;

export const GQL_GET_NEXT_MEMORY_ANCHORING_QUESTION = gql`
  query GetNextMaResource($external_user_id: String!) {
    getNextMaResource(external_user_id: $external_user_id) {
      ma_resource_id
      question_text
      answer_text
      ma_concept_label
      ma_concept_id
      ma_resource_queue_id
      ma_resource_queue_name
      message
      error
    }
  }
`;

export const GQL_ANSWER_MEMORY_ANCHORING_QUESTION = gql`
  mutation AnswerMaResource(
    $external_user_id: String!
    $ma_resource_id: ID!
    $ma_resource_queue_id: ID!
    $ma_resource_queue_name: String!
    $ma_concept_id: ID!
    $answer: Boolean!
  ) {
    answerMaResource(
      external_user_id: $external_user_id
      ma_resource_id: $ma_resource_id
      ma_resource_queue_id: $ma_resource_queue_id
      ma_resource_queue_name: $ma_resource_queue_name
      ma_concept_id: $ma_concept_id
      answer: $answer
    )
  }
`;

export const GQL_GET_NB_MEMORY_ANCHORING_QUESTION = gql`
  query GetNumberOfMaResources($external_user_id: String!) {
    getNumberOfMaResources(external_user_id: $external_user_id)
  }
`;

export const GQL_ASK_TEACHER = gql`
  mutation AskTeacher(
    $from_external_user_id: String!
    $external_client_id: String!
    $msg_type: String!
    $content: String!
    $sent_at: String!
    $external_chapter_id: String!
    $previous_external_message_ids: [String!]
  ) {
    askTeacher(
      item: {
        from_external_user_id: $from_external_user_id
        external_client_id: $external_client_id
        external_chapter_id: $external_chapter_id
        msg_type: $msg_type
        content: $content
        sent_at: $sent_at
        previous_external_message_ids: $previous_external_message_ids
      }
    ) {
      message
      error
    }
  }
`;

export const GQL_GET_QUESTIONS_FOR_TEACHER = gql`
  query GetAllTheConversationWithTeacher($external_user_id: String!) {
    getAllTheConversationWithTeacher(external_user_id: $external_user_id)
  }
`;

export const GQL_GET_CONVERSATION = gql`
  query GetAllMessagesOfStudentConversation(
    $external_first_message_id: String!
    $external_user_id: String!
  ) {
    getAllMessagesOfStudentConversation(
      external_first_message_id: $external_first_message_id
      external_user_id: $external_user_id
    ) {
      external_id
      from_external_user_id
      recipient_external_user_id
      msg_type
      external_previous_message_id
      external_first_message_id
      content_format
      content
      external_question_id
      external_answer_id
      sent_at
      read_at
      responded_at
    }
  }
`;

export const GQL_RESPOND_TO_TEACHER = gql`
  mutation RespondToTeacher(
    $external_client_id: String!
    $external_course_id: String!
    $external_previous_message_id: String!
    $from_external_user_id: String!
    $content: String
  ) {
    respondToTeacher(
      external_client_id: $external_client_id
      external_course_id: $external_course_id
      external_previous_message_id: $external_previous_message_id
      from_external_user_id: $from_external_user_id
      content: $content
    ) {
      error
      message
      messageForBobStudent {
        external_id
        from_external_user_id
        recipient_external_user_id
        msg_type
        content_format
        content
        sent_at
        read_at
        archived_at
        responded_at
        external_question_id
        external_answer_id
        external_course_id
        external_chapter_id
        external_previous_message_id
        external_first_message_id
        meta_data
      }
    }
  }
`;

export const GQL_SET_READ_MESSAGE = gql`
  mutation SetReadMessage(
    $external_user_id: String!
    $external_message_id: String!
  ) {
    setReadMessage(
      external_user_id: $external_user_id
      external_message_id: $external_message_id
    ) {
      error
      message
    }
  }
`;

export const GQL_GET_MA_USER_STATUS = gql`
  query GetMaUserStatus($external_user_id: String!) {
    getMaUserStatus(external_user_id: $external_user_id) {
      ma_active
      ma_email_active
      error
      message
    }
  }
`;

export const GQL_SUBSCRIBE_MA_USER = gql`
  mutation SubscribeMaUser(
    $external_user_id: String!
    $keepHistory: Boolean!
    $email_active: Boolean!
    $email_address: String!
    $external_client_id: String
  ) {
    subscribeMaUser(
      external_user_id: $external_user_id
      keepHistory: $keepHistory
      email_active: $email_active
      email_address: $email_address
      external_client_id: $external_client_id
    ) {
      error
      message
    }
  }
`;

export const GQL_SUBSCRIBE_MA_MAIL = gql`
  mutation SubscribeMaMail($external_user_id: String!) {
    subscribeMaMail(external_user_id: $external_user_id) {
      error
      message
    }
  }
`;

export const GQL_GET_ANSWER_RATE = gql`
  query GetRateCommentAnswerByStudent(
    $external_user_id: String!
    $external_answer_id: String!
  ) {
    getRateCommentAnswerByStudent(
      external_user_id: $external_user_id
      external_answer_id: $external_answer_id
    ) {
      error
      message
      rating
      comment
    }
  }
`;

export const GQL_RATE_ANSWER = gql`
  mutation RateAnswerByStudent(
    $external_user_id: String!
    $external_answer_id: String!
    $rating: Int!
  ) {
    rateAnswerByStudent(
      external_user_id: $external_user_id
      external_answer_id: $external_answer_id
      rating: $rating
    ) {
      error
      message
    }
  }
`;

export const GQL_GET_QUESTION_TAG_TYPE = gql`
  query GetQuestionTagType(
    $external_question_id: String!
    $external_user_id: String!
  ) {
    getQuestionTagType(
      external_question_id: $external_question_id
      external_user_id: $external_user_id
    ) {
      error
      message
      questionTags
      questionType
    }
  }
`;

export const GQL_GET_QUESTION_ANSWER_TAG_TYPE = gql`
  query GetQuestionTagTypeFromQuestionAnswer(
    $question_answer_id: ID!
    $external_user_id: String!
  ) {
    getQuestionTagTypeFromQuestionAnswer(
      question_answer_id: $question_answer_id
      external_user_id: $external_user_id
    ) {
      error
      message
      questionTags
      questionType
    }
  }
`;

export const GQL_GET_ANSWER_TAG_TYPE = gql`
  query GetQuestionTagTypeFromAnswer(
    $answer_id: ID!
    $external_user_id: String!
  ) {
    getQuestionTagTypeFromAnswer(
      answer_id: $answer_id
      external_user_id: $external_user_id
    ) {
      error
      message
      questionTags
      questionType
    }
  }
`;

export const GQL_GET_BOB_CUSTOM_WITH_COURSE_ID = gql`
  query GetBobCustomization(
    $external_course_id: String!
    $tag: String!
  ) {
    getBobCustomization(
      external_course_id: $external_course_id
      tag: $tag
    ) {
      error
      message
      customizationSettings
      external_client_id
    }
  }
`;

export const GQL_LIST_3WA_AL_CONCEPTS = gql`
  query List3waALConcepts(
    $external_client_id: String!
    $external_user_id: String!
  ) {
    list3waALConcepts(
      external_client_id: $external_client_id
      external_user_id: $external_user_id
    ) {
      error
      message
      data {
        id
        label
        score
      }
    }
  }
`;

export const GQL_SEND_3WA_AL_SCORE = gql`
  mutation SendAlConceptScore3wa(
    $external_client_id: String!
    $external_user_id: String!
    $al_concept_id: ID!
    $score: Float!
  ) {
    sendAlConceptScore3wa(
      external_client_id: $external_client_id
      external_user_id: $external_user_id
      al_concept_id: $al_concept_id
      score: $score
    ) {
      error
      message
      data {
        id
        label
        score
      }
    }
  }
`;

export const GQL_GET_BOB_CHAT_HISTORY = gql`
  query GetBobChatHistory(
    $external_user_id: String!
    $external_course_id: String!
    $external_client_id: String!
    $limit: Int
    $offset: Int
  ) {
    getBobChatHistory(
      external_user_id: $external_user_id
      external_course_id: $external_course_id
      external_client_id: $external_client_id
      limit: $limit
      offset: $offset
    ) {
      external_id
      from_external_user_id
      recipient_external_user_id
      msg_type
      content_format
      content
      sent_at
      read_at
      archived_at
      responded_at
      external_question_id
      external_answer_id
      external_course_id
      external_chapter_id
      external_previous_message_id
      external_first_message_id
      meta_data
    }
  }
`;

export const GQL_LIST_CONVERSATIONS_WITH_TEACHER = gql`
  query ListConversationsWithTeacherForStudent(
    $external_user_id: String!
    $external_course_id: String!
    $external_client_id: String!
  ) {
    listConversationsWithTeacherForStudent(
      external_user_id: $external_user_id
      external_course_id: $external_course_id
      external_client_id: $external_client_id
    ) {
      firstMessage {
        content
        sent_at
        external_id
        msg_type
      }
      lastMessage {
        content
        sent_at
        external_id
        msg_type
      }
      nb_unread_messages
      nb_messages_total
      external_conversation_id
      closed_at
    }
  }
`;

export const GQL_SAVE_MESSAGE = gql`
  mutation SaveMessageBobFromBobChat(
    $msg_type: BobMessageType!
    $external_user_id: String!
    $content: String
    $external_previous_message_id: String
    $external_first_message_id: String
    $external_question_id: String
    $external_answer_id: String
    $external_question_answer_id: String
    $external_client_id: String
    $external_course_id: String
    $meta_data: JSONObject
  ) {
    saveMessageBobFromBobChat(
      msg_type: $msg_type
      external_user_id: $external_user_id
      content: $content
      external_previous_message_id: $external_previous_message_id
      external_first_message_id: $external_first_message_id
      external_question_id: $external_question_id
      external_answer_id: $external_answer_id
      external_question_answer_id: $external_question_answer_id
      external_client_id: $external_client_id
      external_course_id: $external_course_id
      meta_data: $meta_data
    ) {
      external_id
    }
  }
`;

export const GQL_SET_CONVERSATION_AS_READ = gql`
  mutation SetConversationMessagesAsReadStudent(
    $external_user_id: String!
    $external_conversation_ids: [String!]!
  ) {
    setConversationMessagesAsReadStudent(
      external_user_id: $external_user_id
      external_conversation_ids: $external_conversation_ids
    ) {
      error
      message
    }
  }
`;
