import React from "react";
import { useSelector } from "react-redux";

export const ThemeContext = React.createContext({});

export const ThemeProvider = ({ children }) => {
  const messages = useSelector((state)=>state.app.theme.messages)

  const t = (label) => {
    return messages.translation[label] || label;
  };

  return (
    <ThemeContext.Provider value={{ t }}>
      {children}
    </ThemeContext.Provider>
  );
};
