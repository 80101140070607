import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import InlineMathViewer from './InlineMathViewer';

export default Node.create({
  name: 'inline-math',  
  inline: true,
  group: "inline",
  selectable: false,

  atom: true,

  addAttributes() {
    return {
      math: {
        default: ""
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'inline-math',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['inline-math', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(InlineMathViewer)
  },
})