export const PANES = {
  ALL_WIDGETS: "ALL_WIDGETS",
  CHAT: "CHAT",
  TEACHER_ANSWERS: "TEACHER_ANSWERS",
  MEMORY_ANCHORING: "MEMORY_ANCHORING",
  ADAPTIVE_LEARNING: "ADAPTIVE_LEARNING",
  INFO: "INFO",
  SETTINGS: "SETTINGS",
};

export const IDLE_TIMEOUT = {
  IDLE_TIMEOUT: 1000,
  IDLE_DISAPPEAR_TIMEOUT: 10000,
};

export const DIMENSIONS = {
  SIDEBAR_HEIGHT: "20rem",
  PANE_WIDTH: "20rem",
  PANE_HEIGHT: "28rem",
  EXPANDED_PANE_WIDTH: "42rem",
  EXPANDED_PANE_HEIGHT: "40rem",
};

export const LAYOUT = {
  SIDEBAR_POSITION: {
    BOTTOM: "BOTTOM",
    CENTER: "CENTER",
  },
  BOB_POSITION: {
    LEFT: "LEFT",
    RIGHT: "RIGHT",
  },
};
export const TIMER = {
  TIMER_VALUE: 1,
};
export const THEME = {
  THEME_DEFAULT_VALUE: "DEFAULT",
  THEME_ESB_VALUE: "ESB",
  THEME_ASU_VALUE: "ASU",
};

export const TOKENS = {
  BOB_STUDENT_TOKEN: "Bearer Bob Student",
};
