import Color from "color";
import { LAYOUT, PANES } from "../constants/constants";
import defaultMessages from "../client_custom/default";
import esbCustomMessages from "../client_custom/esb";
import asuCustomMessages from "../client_custom/asu";
import cnedCustomMessages from "../client_custom/cned";
import ascorCustomMessages from "../client_custom/ascor";

export const transformCustomData = (state, custom) => {
  const handleClientID = (state, external_client_id) => {
    state.id.external_client = external_client_id;
    if (external_client_id === "vzqMyXtTmbXwZ1") {
      state.theme.messages = esbCustomMessages.default;
      state.general.knowledge_anchoring_visible = true;
    } else if (external_client_id === "wI8uqv3BcEh2r5") {
      state.general.active_pane = PANES.CHAT;
      state.general.disable_conversation_starter = true;
      state.general.pane_visible = true;
      state.theme.messages = asuCustomMessages.default;
    } else if (external_client_id === "469785f61e5c7e") {
      if (state.id.external_course === "def36ba546e485") {
        state.theme.messages = cnedCustomMessages.ent;
      } else state.theme.messages = cnedCustomMessages.default;
    } else if (external_client_id === "adLz6AKrQw83EC") {
      state.theme.messages = defaultMessages.fr;
      state.general.adaptive_learning_visible = true;
    } else if (external_client_id === "ea5483b7af4ad0") {
      state.general.knowledge_anchoring_visible = true;
      state.theme.messages = defaultMessages.fr;
    } else if (external_client_id === "owUMSEs82weY8l") {
      state.theme.messages = ascorCustomMessages.default;
    }
    return state;
  };

  const handleThemeColors = (color) => {
    return {
      primary: {
        base: color.primary.hex(),
        strong: color.primary.darken(0.2).hex(),
        extrastrong: "#",
        light:
          color.primary.luminosity() > 0.1
            ? color.primary.luminosity() > 0.2
              ? color.primary.lighten(0.7).whiten(0.3).hex()
              : color.primary.whiten(0.8).lighten(0.4).hex()
            : color.primary.whiten(0.9).lighten(0.5).hex(),
        extralight:
          color.primary.luminosity() > 0.1
            ? color.primary.lighten(0.1).whiten(0.7).hex()
            : color.primary.lighten(0.9).whiten(0.9).hex(),
      },
      secondary: {
        base: color.secondary.hex(),
      },
      answer_background: {
        base: color.answer_background.hex(),
      },
    };
  };

  state.theme.color.primary = Color(
    custom?.customizationSettings?.bobMainColor?.value_string ||
      state.theme.color.primary
  );
  state.theme.color.secondary = Color(
    custom?.customizationSettings?.bobHeaderColor?.value_string ||
      state.theme.color.secondary
  );
  state.theme.color.answer_background = Color(
    custom?.customizationSettings?.bobAnswerColor?.value_string ||
      state.theme.color.answer_background
  );

  state.theme.color.theme_colors = handleThemeColors(state.theme.color);
  state.general.expanded_pane =
    custom?.customizationSettings?.bobDefaultWindowSize?.value_string ===
    "Extended"
      ? true
      : false;
  state.general.bob_position =
    custom?.customizationSettings?.bobInterfacePosition?.value_string === "Left"
      ? LAYOUT.BOB_POSITION.LEFT
      : LAYOUT.BOB_POSITION.RIGHT;

  state.general.nb_auto_suggestions =
    parseInt(
      custom?.customizationSettings?.bobNbAutoSuggestions?.value_string
    ) || state.general.nb_auto_suggestions;

  if (custom?.external_client_id) {
    state = handleClientID(state, custom.external_client_id);
  }

  if (
    custom?.customizationSettings?.questionsSuggestions?.value_json?.activated
  ) {
    state.chat.onboarding_questions =
      custom?.customizationSettings?.questionsSuggestionsData?.value_json
        ?.questions || [];
  } /* else {
    state.chat.chat_data_array = [
      ...state.chat.chat_data_array,
      {
        answer: null,
        chapterID: null,
        conversationID: null,
        courseID: null,
        chat: {
          text: state.theme.messages.translation["first-message"],
          type: "chat",
          original_question: null,
        },
        related_questions: null,
        timestamp: new Date(),
        user: {
          userID: "0",
          username: null,
        },
      },
    ];
  }*/

  state.theme.loaded = true;

  return state;
};
