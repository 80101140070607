import PaneContent from "../components/PaneContent";
import { useEffect, useState } from "react";
import { RiLock2Fill } from "react-icons/ri";
import { BsFillStarFill } from "react-icons/bs";
import { TIMER } from "../../../constants/constants";
import { Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";

import {
  XgetALConcepts,
  XsetALStage,
  XsetALCurrentConcept,
  XsetALCurrentQuestionIndex,
  XpostALScore,
  XresetALQuestions,
  XsetALQuestions,
} from "../../../appSlice";

const scoreToStars = (score) => {
  if (score < 100) {
    if (score < 80) {
      if (score < 60) {
        return 0;
      } else return 1;
    } else return 2;
  } else return 3;
};

const ALTest = () => {
  const dispatch = useDispatch();
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const current_question_index = useSelector(
    (state) => state.app.adaptive_learning.current_question_index
  );
  const current_concept = useSelector(
    (state) => state.app.adaptive_learning.current_concept
  );
  const questions = useSelector(
    (state) => state.app.adaptive_learning.questions
  );
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const score = useSelector((state) => state.app.adaptive_learning.score);

  const [timeLeft, setTimeLeft] = useState(TIMER.TIMER_VALUE * 100);
  const [stage, setStage] = useState("timer"); //timer -> answer -> next -> congrats
  const switchState = (state) => {
    switch (state) {
      case 0:
        return "border border-gray-300";
      case 1:
        return "";
      case 2:
        return "bg-gray-300 text-gray-400";
      case 3:
        return "ring-2 ring-green-400 bg-green-400 text-green-500";
      case 4:
        return "ring-2 ring-red-400 bg-red-400 text-red-500";
      default:
        return "";
    }
  };

  const displayScore = (score) => {
    return (
      <div className={`flex gap-1 m-auto text-2xl`}>
        {[...Array(scoreToStars(score))].map((e, index) => (
          <BsFillStarFill
            key={index + "active"}
            style={{
              color: themeColors.primary.base,
            }}
          />
        ))}
        {[...Array(3 - scoreToStars(score))].map((e, index) => (
          <BsFillStarFill
            key={index}
            className="opacity-30"
            style={{
              color: themeColors.primary.base,
            }}
          />
        ))}
      </div>
    );
  };

  const displayCongratsMessage = (score) => {
    if (score < 100) {
      if (score < 80) {
        if (score < 60) {
          return " Ce concept ne semble pas faire parti de vos connaissances, vous avez de nombreuses notions à découvrir.";
        } else
          return "Ce concept ne vous est pas inconnu mais il vous reste encore des notions à découvrir ou à approfondir ! ";
      } else
        return "Vous n’etes pas novice face à ce concept. Vous devriez apprendre rapidement les notions qu’il vous manque. ";
    } else
      return "Un sans faute pour ce concept, à priori vous connaissez déjà très bien le sujet ! ";
  };

  const correctAnswer = () => {
    setStage("next");
    let current = JSON.parse(JSON.stringify(questions[current_question_index]));
    current["state"] = 3;
    if (questions.length > current_question_index + 1) {
      let second = JSON.parse(JSON.stringify(questions[current_question_index + 1]));
      second["state"] = 2;
      if (questions.length > current_question_index + 2) {
        let third = JSON.parse(JSON.stringify(questions[current_question_index + 2]));
        third["state"] = 2;
        dispatch(
          XsetALQuestions([
            ...questions.slice(0, current_question_index),
            current,
            second,
            third,
            ...questions.slice(current_question_index + 3),
          ])
        );
      } else {
        dispatch(
          XsetALQuestions([
            ...questions.slice(0, current_question_index),
            current,
            second,
            ...questions.slice(current_question_index + 2),
          ])
        );
      }
    } else {
      dispatch(
        XsetALQuestions([
          ...questions.slice(0, current_question_index),
          current,
          ...questions.slice(current_question_index + 1),
        ])
      );
    }
  };

  const badAnswer = () => {
    setStage("next");
    let current = JSON.parse(JSON.stringify(questions[current_question_index]));
    current["state"] = 4;
    dispatch(XsetALQuestions([
      ...questions.slice(0, current_question_index),
      current,
      ...questions.slice(current_question_index + 1),
    ]));
  };

  useEffect(() => {
    if (stage === "timer") {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 100);
      if (!timeLeft) {
        setStage("answer");
      }
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  useEffect(() => {
    stage === "timer" && setTimeLeft(TIMER.TIMER_VALUE * 100);
  }, [stage]);

  return (
    <div
      className={`flex py-2 h-full w-full ${
        !expanded_pane &&
        questions[current_question_index]?.answer?.length > 400
          ? ""
          : "items-center"
      }`}
    >
      <div className="flex flex-col flex-grow text-center gap-3">
        <div className="text-gray-400 text-3xs font-light">
          {current_concept.name}
        </div>
        <div className="flex gap-2 mx-auto">
          {questions.map((question, index) => (
            <div
              key={index}
              className={`flex items-center w-5 h-5 ${switchState(
                question.state
              )}`}
              style={
                index === current_question_index && question.state === 0
                  ? {
                      outline: "solid " + themeColors.primary.base,
                      borderStyle: "none",
                    }
                  : {}
              }
            >
              <div className="mx-auto font-medium">{index + 1}</div>
            </div>
          ))}
        </div>
        {stage !== "congrats" && (
          <div className="text-base font-medium">
            {questions[current_question_index]?.question}{" "}
          </div>
        )}
        {stage === "timer" && (
          <Progress
            className="w-64 mx-auto"
            showInfo={false}
            size="small"
            percent={timeLeft / TIMER.TIMER_VALUE}
            strokeColor={themeColors.primary.base}
          />
        )}
        {(stage === "answer" || stage === "next") && (
          <div
            className={`text-gray-500 ${expanded_pane ? "px-20" : "px-6"} ${
              questions[current_question_index]?.answer?.length > 100 &&
              "text-left"
            }`}
          >
            {questions[current_question_index]?.answer}
          </div>
        )}
        {stage === "answer" && (
          <div className="flex gap-3 mx-auto mb-2">
            <div
              className="border-2 rounded-md px-4 py-2 shadow-lg font-medium cursor-pointer"
              style={{
                borderColor: themeColors.primary.base,
                color: themeColors.primary.base,
              }}
              onClick={() => correctAnswer()}
            >
              Je le savais
            </div>
            <div
              className="rounded-md px-4 py-2 text-white shadow-lg font-medium cursor-pointer"
              style={{
                backgroundColor: themeColors.primary.base,
              }}
              onClick={() => badAnswer()}
            >
              Je l'apprends
            </div>
          </div>
        )}
        {stage === "next" && (
          <div className="flex mx-auto">
            {questions.find((question) => question.state === 0) ? (
              <div
                className="rounded-md px-4 py-2 text-white shadow-lg font-medium cursor-pointer"
                style={{
                  backgroundColor: themeColors.primary.base,
                }}
                onClick={() => {
                  dispatch(XsetALCurrentQuestionIndex());
                  setStage("timer");
                }}
              >
                Question suivante
              </div>
            ) : (
              <div
                className="rounded-md px-4 py-2 text-white shadow-lg font-medium cursor-pointer"
                style={{
                  backgroundColor: themeColors.primary.base,
                }}
                onClick={() => {
                  setStage("congrats");
                  dispatch(XpostALScore());
                }}
              >
                Terminer le test
              </div>
            )}
          </div>
        )}
        {stage === "congrats" && (
          <div
            className={`flex flex-col py-2 ${
              expanded_pane ? "gap-5 p-20 text-sm" : "p-4 gap-3"
            }`}
          >
            <div>
              Bravo, vous avez terminer votre session d’apprentissage adaptatif
              <div>{current_concept.name}</div>
            </div>
            {displayScore(score)}
            <div>{displayCongratsMessage(score)}</div>
            <div>Revenez vous tester quand vous le souhaitez...</div>
            <div className="flex mx-auto">
              <div
                className="rounded-md px-4 py-2 text-white shadow-lg font-medium cursor-pointer"
                style={{
                  backgroundColor: themeColors.primary.base,
                }}
                onClick={() => {
                  dispatch(XresetALQuestions());
                }}
              >
                Quitter
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Concept = ({ concept, index }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const concepts = useSelector((state) => state.app.adaptive_learning.concepts);
  const previous_scores = useSelector(
    (state) => state.app.adaptive_learning.previous_scores
  );
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [previous_score, setPrevScore] = useState();

  useEffect(() => {
    const concept_found = previous_scores.find(
      (element) => element.label === concept.name
    );
    if (concept_found) {
      setPrevScore(concept_found.score);
    }
  }, [previous_scores]);

  return (
    <div
      className="flex border-b border-gray-300 items-center px-2"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`truncate flex-grow ${
          previous_score === -1 ? "text-gray-300" : ""
        }`}
      >
        {concept.name}
      </div>
      {hovered && previous_score !== -1 ? (
        <div
          className={`flex items-center my-1 ${
            expanded_pane ? "text-sm h-10" : "text-2xs h-8"
          }`}
        >
          <div
            style={{
              backgroundColor: themeColors.primary.base,
            }}
            className="text-white py-1 px-2 rounded-md cursor-pointer"
            onClick={() => {
              dispatch(XsetALCurrentConcept(concepts[index]));
              dispatch(XsetALStage("test"));
            }}
          >
            Lancer le test
          </div>
        </div>
      ) : (
        <div
          className={`flex justify-items-center my-1 ${
            expanded_pane ? "text-lg h-10" : "text-sm h-8"
          }`}
        >
          {previous_score >= 0 ? (
            <div
              className={`flex justify-items-center ${
                expanded_pane ? "h-10 gap-1" : "h-8 gap-1"
              }`}
            >
              {[...Array(scoreToStars(previous_score))].map((e, index) => (
                <BsFillStarFill
                  key={index + "active"}
                  className="m-auto"
                  style={{
                    color: themeColors.primary.base,
                  }}
                />
              ))}
              {[...Array(3 - scoreToStars(previous_score))].map((e, index) => (
                <BsFillStarFill
                  key={index}
                  className="m-auto opacity-30"
                  style={{
                    color: themeColors.primary.base,
                  }}
                />
              ))}
            </div>
          ) : (
            <RiLock2Fill className="text-gray-300 m-auto" />
          )}
        </div>
      )}
    </div>
  );
};

const ALConcepts = () => {
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const concepts = useSelector((state) => state.app.adaptive_learning.concepts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(XgetALConcepts());
  }, []);

  return (
    <div
      className={`flex flex-col ${
        expanded_pane ? "text-sm gap-3 p-4" : "gap-2 p-2"
      }`}
    >
      <div className="text-2xl font-medium text-black">Concepts</div>
      <div className="flex flex-col">
        {concepts.map((concept, index) => (
          <Concept concept={concept} index={index} key={index} />
        ))}
      </div>
    </div>
  );
};

const ALWelcome = () => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const dispatch = useDispatch();
  return (
    <div className="flex h-full items-center">
      <div
        className={`flex-grow flex flex-col items-center ${
          expanded_pane ? "gap-5 p-20 text-sm" : "p-4 gap-3"
        }`}
      >
        <div className="font-semibold">
          Bienvenue sur l’apprentissage adaptatif 👋
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <div
              className="font-semibold"
              style={{
                color: themeColors.primary.base,
              }}
            >
              Objectif :
            </div>
            <div>
              L’apprentissage adaptatif est un test sur l’ensemble des concepts
              du cours qui permet d’adapter le rythme de l’apprentissage.
            </div>
            <div>
              Vous pouvez aussi l'utiliser comme un outil pour tester vos acquis
              à tout moment et en autonomie.
            </div>
          </div>
          <div>
            <div
              className="font-semibold"
              style={{
                color: themeColors.primary.base,
              }}
            >
              Fonctionnement :
            </div>
            <span>
              Une suite des questions de niveaux croissants est proposée pour
              chaque concept. Plus vous avez de bonnes réponses et plus vous
              progresserez rapidement dans les tests.
            </span>
          </div>
        </div>
        <div
          className="text-white py-2 px-6 rounded-md shadow-lg font-medium mt-6 cursor-pointer"
          style={{
            backgroundColor: themeColors.primary.base,
          }}
          onClick={() => dispatch(XsetALStage("concepts"))}
        >
          C'est parti
        </div>
      </div>
    </div>
  );
};

const AdaptiveLearningContainer = () => {
  const stage = useSelector((state) => state.app.adaptive_learning.stage);
  switch (stage) {
    case "error":
      return <div className="h-full w-full">error</div>;
    case "welcome":
      return (
        <div className="h-full w-full">
          <ALWelcome />
        </div>
      );
    case "concepts":
      return (
        <div className="h-full w-full">
          <ALConcepts />
        </div>
      );
    case "test":
      return (
        <div className="h-full w-full">
          <ALTest />
        </div>
      );
    default:
      return <div className="h-full w-full"></div>;
  }
};

const AdaptiveLearningPane = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(XgetALConcepts());
  }, []);

  return (
    <PaneContent>
        <AdaptiveLearningContainer />
    </PaneContent>
  );
};

export default AdaptiveLearningPane;
