import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useSelector } from "react-redux";

const InfoCard = ({ card }) => {
  const [isOpen, setOpen] = useState(false);
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  return (
    <div className="flex flex-col items-center">
      <div
        style={{
          backgroundColor: isOpen ? "#FFFFFF" : themeColors.primary.base,
          color: isOpen ? themeColors.primary.base : "#FFFFFF",
          borderColor: isOpen ? themeColors.primary.base : "#FFFFFF",
        }}
        className={`flex rounded-lg w-11/12 p-2 items-center  border-2 cursor-pointer ${
          expanded_pane ? "text-base" : "text-sm"
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        {card.question}
        <div className="ml-auto rounded-full hover:bg-gray-300 hover:bg-opacity-20  p-1">
          {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      <div
        className={`rounded-b-lg w-10/12 p-3 ${!isOpen && "hidden"}`}
        style={{
          backgroundColor: themeColors.answer_background,
        }}
      >
        {card.description}
      </div>
    </div>
  );
};

export default InfoCard;
