import { useContext, useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { RiVipCrown2Fill } from "react-icons/ri";
import { ThemeContext } from "../../../../../ThemeProvider";
import HintLabel from "./HintLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  XaskBob,
} from "../../../../../../appSlice";
import AnswerRender from "../../../../components/AnswerRender";

const HintsItem = ({ hint, index }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [hovered, setHovered] = useState(false);
  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );
  const dispatch = useDispatch();
  return (
    <div
      style={
        hovered
          ? {
              backgroundColor: themeColors.primary.base,
            }
          : {}
      }
      className={` rounded py-1 flex items-center ${
        hovered ? ` text-white ` : "text-gray-600"
      }`}
      key={index}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div
        id={"suggestion_autocomplete_" + (index + 1)}
        className="flex-1 flex items-center cursor-pointer"
        onClick={() => {
          dispatch(
            XaskBob({
              input: hint.text,
              origin: "hints",
              from: null,
              userInput: bob_input_value,
            })
          );
        }}
      >
        <div
          className={`text-3xs pr-1 hover:text-current ${
            hint.score > 1.91 && index === 0
              ? "text-yellow-300"
              : "text-gray-300"
          }`}
        >
          {hint.score > 1.91 && index === 0 ? (
            <RiVipCrown2Fill />
          ) : (
            <BsFillCaretRightFill />
          )}
        </div>

        {hint.highlight[0] && !hint.text.match(/\$(.*?)\$/) ? (
          <AnswerRender text={hint.highlight[0]} />
        ) : (
          <AnswerRender text={hint.text} />
        )}
      </div>
      <HintLabel className="flex-1" hint={hint} />
    </div>
  );
};

export default HintsItem;
