const resources = {
  default: {
    translation: {
      // chat
      "bot-out-of-order":
        "J'ai du mal à répondre à votre question, il semble que le système soit en panne, veuillez réessayer plus tard.",
      "bot-name": "Bob",
      "first-message": "Bonjour! Je suis ici pour vous aider! 😄",
      "onboarding-message-1":
        "Je m'appelle Bob, je suis un assistant virtuel. Je suis ici pour vous aider.",
      "onboarding-message-2":
        "Posez moi une question ou cliquez sur une des suggestions suivantes:",
      "question-sent": "Votre question a bien été envoyée! 😄",
      "question-edit-sent":"Modifiez et envoyez votre question au formateur :",
      "tips-ask-bob":
        "Répondre aux questions de cours est ma spécialité, vous pouvez m'interroger et sélectionner une question parmi les suggestions.",
      or: "ou",
      no: "Non",
      yes: "Oui",
      "question-confirm": "Voulez-vous demander à un formateur?",
      "see-less": "voir moins",
      "see-more": "voir plus",
      expand: "Agrandir",
      contract: "Réduire (réponse raccourcie)",
      "explore-source": "Visiter la source",
      // hints:
      "see-more-hints": "(voir plus)",
      // video
      video: "Vidéo",
      loop: "Boucle",
      speed: "vitesse",
      start: "Début",
      end: "Fin",
      "ab-test-alert":
        "Choisissez votre question parmi l’une de celles affichées ou envoyez votre propre question à un Formateur.",
      "askteacher-aborted": "Vous avez abandonné l'envoie d'une question à un formateur.",
      // App
      "conversation-starter": "👋 Bonjour! Prêt pour aujourd'hui?",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Demandez à Bob!",
      "pane-header-teacher-questions": "Réponses du formateur",
      "pane-header-memory-anchoring": "Mémorisation",
      "pane-header-settings": "Paramètres",
      "pane-header-info": "Info",
      "ask-teacher-directly": "Posez votre question directement à un Formateur",
      "ask-bob": "Demandez à Bob par mot-clé !",
      "your-question-for-teacher": "Votre question pour un formateur:",
      //tooltip
      "tooltip-chat": "Posez moi des question",
      "tooltip-teacher": "Accèdez aux réponses des formateurs",
      "tooltip-KA": "Entrainez vous avec l'ancrage mémoriel",
      "tooltip-AL": "Testez vous avec l'apprentissage adaptatif",
      "tooltip-info": "Salut, moi c'est Bob...",
      // teacher answers
      "mark-unread": "marquer comme non-lu",
      "teacher-answer-new-question": "Avez-vous de nouvelles questions ? ",
      "teacher-answer-description":
        "Retrouvez ici vos conversations avec les formateurs",
      refresh: "Actualiser",
      "respond-to-teacher": "Échangez avec les formateurs",
      you: "Vous: ",
      answer: "Réponse: ",
      reply: "Répondre",
      "conversation-closed":
        "Cette conversation a été close par le formateur, vous pouvez la rouvrir en cliquant ci-dessous",
      // memory anchoring
      "go-to-answer": "Voir la réponse",
      "accept-tcu-1": "J'ai pris connaissance et accepte les ",
      "accept-tcu-2":
        "Conditions Générales d'Utilisation et Informations relatives aux données personnelles",
      "memory-anchoring-3-questions":
        "Quelques questions pour entraîner votre mémoire",
      "memory-anchoring-lets-go": "C'est parti!",
      "memory-anchoring-user-answers-I-knew-it": "Je le savais!",
      "memory-anchoring-user-answers-I-am-learning-it": "Je l'apprends!",
      "memory-anchoring-congratulations": "🎉 Bravo !",
      "memory-anchoring-come-again":
        "Revenez demain pour continuer l’entraînement !",
      "memory-anchoring-come-again-content":
        "Ferez-vous aussi bien qu’aujourd’hui ? Plus qu’hier et moins que demain ?",
      "demo-description":
        "Les tests d’ancrage sont envoyés à l’élève à +2, +7, +30 jours après chaque test réussi. En cas d’échec, le cycle est réinitialisé. Après 3 échecs, le prof est alerté.",
      // dashboard
      "dashboard-memory-anchoring-title": "Ancrage Mémoriel",
      "dashboard-memory-anchoring-content":
        "Vérifiez vos connaissances avec 3 nouvelles questions!",
      "dashboard-teacher-questions-title": "Réponses formateurs",
      "dashboard-teacher-questions-content-has-answers":
        "Vous avez de nouvelles réponses!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chatez et posez vos questions aux formateurs!",
      "dashboard-chat-title": "Demandez à Bob!",
      "dashboard-chat-content":
        "Bob est là pour vous aider! Posez lui une question!",
      // settings
      "bob-language": "Langue Bob:",
      "bob-language-en": "Anglais",
      "bob-language-fr": "Français",
      "settings-position-label": "Position de Bob:",
      "settings-position-right": "Droite",
      "settings-position-left": "Gauche",
      "settings-sidebar-position-label": "Position de la Sidebar:",
      "settings-sidebar-position-center": "Centre",
      "settings-sidebar-position-bottom": "Bas",
      theme: "Thème:",
      "theme-default": "Bob",
      "theme-asu": "ASU",
      "coming-soon": "Bientôt disponible!",
      "welcome-knowledge-anchoring-page":
        "Bienvenue à l'Ancrage de Connaissances ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "Il n'y a pas question pour cette fois.",
      "memory-anchoring-come-again-tomorow": "Revenez demain !",
      "memory-anchoring-description-1":
        "Cette méthode permet de mémoriser plus efficacement et durablement ce que  vous apprenez. ",
      "memory-anchoring-description-2":
        "Inscrivez-vous pour recevoir jusqu'à 3 questions par jour qui vont stimuler votre esprit et améliorer la rétention de vos connaissances dans le temps.",
      "enable-email-reminders": "Activer les rappels par email",
      "sign-in": "S'inscrire",
      "is-this-useful": "Cette réponse est-elle utile ?",
      "similar-question": "Voici une liste des questions similaires:",
      "similar-question-original-question":
        "Voici une liste de questions similaires pour votre recherche",
      "bob-found-answer": "J'espère que cela répond à votre question:",
      "bob-found-similar-question":
        "Voici la question que j'ai sélectionnée et sa réponse associée : ",
    },
  },
};

export default resources;
