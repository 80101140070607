import { DIMENSIONS, LAYOUT } from "../../../constants/constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const PaneContainer = ({
  children,
  bobPosition,
}) => {
  
  const expanded_pane = useSelector((state)=>state.app.general.expanded_pane)
  const iFrame_type = useSelector((state)=>state.app.general.iFrame_type)
  const sidebar_position = useSelector((state)=>state.app.general.sidebar_position)
  const open_on_mobile_only = useSelector((state)=>state.app.general.open_on_mobile_only)
  const getRounded = () => {
    if (sidebar_position === LAYOUT.SIDEBAR_POSITION.BOTTOM) {
      if (bobPosition === LAYOUT.BOB_POSITION.RIGHT) {
        return "rounded-br-none ";
      }
      if (bobPosition === LAYOUT.BOB_POSITION.LEFT) {
        return "rounded-bl-none ";
      }
    }
    if (sidebar_position === LAYOUT.SIDEBAR_POSITION.CENTER) {
      return "";
    }
  };

  const [window_size, setWindowSize] = useState({
    width:
    iFrame_type === "responsive" ? "100vw" : DIMENSIONS.EXPANDED_PANE_WIDTH,
    height:
    iFrame_type === "responsive" ? "100vh" : DIMENSIONS.EXPANDED_PANE_HEIGHT,
  });

  useEffect(() => {
    window.parent.postMessage("window_size_request_from_bob", "*");
    const handleMessage = (event) => {
      if (event.data?.info === "window_size") {
        setWindowSize(event.data);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div
      id="PaneContainer"
      style={{
        boxShadow:
          open_on_mobile_only && iFrame_type === "responsive"
            ? "none"
            : "#11182733 0px 0px 16px 2px",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateAreas: "header main footer",
        height:
        iFrame_type === "responsive"
            ? "100%"
            : expanded_pane
            ? DIMENSIONS.EXPANDED_PANE_HEIGHT
            : DIMENSIONS.PANE_HEIGHT,
        width:
        iFrame_type === "responsive"
            ? "100%"
            : expanded_pane
            ? DIMENSIONS.EXPANDED_PANE_WIDTH
            : DIMENSIONS.PANE_WIDTH,
        marginRight: "-2px",
        maxWidth: window_size.width,
        maxHeight: window_size.height,
      }}
      className={`bg-white ${
        open_on_mobile_only && iFrame_type === "responsive" ? " " : " rounded-2xl "
      } ${getRounded()} z-20 relative`}
    >
      {children}
    </div>
  );
};

export default PaneContainer;
