import { HiOutlineVideoCamera } from "react-icons/hi";
import { useSelector } from "react-redux";

const HintLabel = ({ hint }) => {
  const dev_mode = useSelector((state)=>state.app.general.dev_mode)
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  const QuestionIdLabel = () => {
    return (
      <div
        style={{
          color: themeColors.primary.base,
        }}
        className={`ml-2 text-3xs font-medium flex`}
      >
        <div
          style={{
            borderColor: themeColors.primary.base,
          }}
          className={`px-2 ml-1 border rounded-xl bg-white`}
        >
          {hint.external_id}
        </div>
      </div>
    );
  };
    return (
      <div
        style={{
          color: themeColors.primary.base,
        }}
        className={`flex`}
      >
        {(hint?.type === "video" ||
          hint?.tags.includes("video")) && (
          <HiOutlineVideoCamera
            className="mr-1 bg-white rounded-lg"
            style={{ fontSize: 18 }}
          />
        )}

        {dev_mode && (
          <QuestionIdLabel className="mr-1 bg-white rounded-lg" />
        )}
      </div>
    );
  
};

export default HintLabel;
