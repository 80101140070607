import { createGlobalStyle } from "styled-components";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppDataWrapper from "./App";
import { ThemeProvider } from "./components/ThemeProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";

const handleGlobalStyle = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  if (params.get("iframetype") === "responsive") {
    return createGlobalStyle`
    #root {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    `;
  } else
    return createGlobalStyle`
  html {
    margin-top: auto;
    margin-left: auto;
    width: fit-content;
    height: fit-content;
  }
  
  #root {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
  }
  `;
};

const GlobalStyles = handleGlobalStyle();

Sentry.init({
  dsn: "https://ee0cbe2e042a4d1dacb615b7d76cee8c@sentry.professorbob.ai/2",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_STAGE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_STAGE === "prod" ? 0.3 : 1.0,
  normalizeDepth: 10,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <GlobalStyles />
        <AppDataWrapper />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
