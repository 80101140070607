import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import AnswerRender from "../../../../components/AnswerRender";

const ChatMessageItemUser = forwardRef(
  ({ chat, dataArrayIndex, needScrollFromMessageOrigin, is_from_history }, olderMessageScrollRef) => {
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );

    useEffect(() => {
      needScrollFromMessageOrigin(is_from_history);
    }, []);
    return (
      <div className="flex w-full" ref={olderMessageScrollRef}>
        <div className="w-1/6" />
        <div className="flex flex-col items-end w-5/6">
          <div
            style={{
              backgroundColor: themeColors.primary.base,
            }}
            className="flex flex-col ml-6 rounded-lg rounded-tr-sm p-3 text-white whitespace-normal"
          >
            <AnswerRender text={chat.text} />
          </div>
        </div>
      </div>
    );
  }
);

export default ChatMessageItemUser;
