import { Select, Slider } from "antd";
import React, { useContext, useRef, useState, useEffect } from "react";
import {
  HiOutlineFastForward,
  HiOutlineRewind,
  HiOutlineVideoCamera,
  HiOutlineVolumeOff,
  HiOutlineVolumeUp,
} from "react-icons/hi";
import { BiPlayCircle, BiPauseCircle } from "react-icons/bi";
import ReactPlayer from "react-player";
import { ThemeContext } from "../../ThemeProvider";
import { useSelector } from "react-redux";
const { Option } = Select;

const PlayerControls = ({
  playing,
  handlePlayPause,
  handleRewind,
  handleFastForward,
  muted,
  handleMute,
  handleVolumeChange,
  handlePlaybackRateChange,
  played,
  handleSeek,
  elapsedTime,
  totalDuration,
  duration,
}) => {
  const formatter = () => elapsedTime;
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  return (
    <div
      style={{
        color: themeColors.primary.base,
      }}
      className={`rounded-b-lg opacity-90`}
    >
      <div className="w-full pt-1">
        <Slider
          value={played * 100}
          onChange={handleSeek}
          tipFormatter={formatter}
          step={duration / 100000}
        />
      </div>

      <div
        id="controls"
        style={{
          color: themeColors.primary.base,
        }}
        className={`grid grid-cols-3 items-center -mt-1 py-1 rounded mb-2`}
      >
        <div className="flex flex-col items-center">
          <div className="text-3xs">
            {elapsedTime}/{totalDuration}
          </div>
          <div
            style={{
              color: themeColors.primary.base,
            }}
            className={`flex items-center justify-center text-xl`}
          >
            <div
              className="flex flex-col items-center justify-center cursor-pointer"
              onClick={handleRewind}
            >
              <HiOutlineRewind />
            </div>
            <div
              className="flex flex-col items-center justify-center text-2xl cursor-pointer"
              onClick={() => handlePlayPause()}
            >
              {playing ? (
                <BiPauseCircle />
              ) : (
                <BiPlayCircle />
              )}
            </div>

            <div
              className="flex flex-col items-center justify-center cursor-pointer"
              onClick={handleFastForward}
            >
              <HiOutlineFastForward />
            </div>
          </div>
        </div>

        <div
          id="sound-controls"
          className="flex flex-col items-center justify-center ml-1"
        >
          {!muted && (
            <div className=" w-full h-5 pb-2">
              <Slider
                defaultValue={70}
                onChange={handleVolumeChange}
                style={{ padding: 0, color: "blue" }}
              />
            </div>
          )}
          <div
            className="items-center cursor-pointer text-lg"
            onClick={handleMute}
          >
            {muted ? <HiOutlineVolumeOff /> : <HiOutlineVolumeUp />}
          </div>
        </div>
        <div className="leading-3 flex flex-col items-center">
          <Select
            defaultValue="1x"
            style={{
              width: 60,
              color: themeColors.primary.base,
            }}
            onChange={handlePlaybackRateChange}
            size="small"
            bordered={false}
          >
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={0.9}
            >
              0.9x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1}
            >
              1x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.1}
            >
              1.1x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.2}
            >
              1.2x
            </Option>
            <Option
              style={{
                color: themeColors.primary.base,
              }}
              className={`text-2xs`}
              value={1.5}
            >
              1.5x
            </Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const TeacherVideoPlayer = ({ url }) => {
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [played, setPlayed] = useState(0);
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };
  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
  };
  const handleMute = () => {
    setMuted(!muted);
  };
  const handleVolumeChange = (e) => {
    setVolume(e / 100);
  };
  const handlePlaybackRateChange = (e) => {
    // console.log({ e });
    setPlaybackRate(e);
  };
  const handleProgress = (e) => {
    // console.log("progress", { e });
    const { played, playedSeconds } = e;
    setPlayed(played);
  };
  const handleSeek = (e) => {
    playerRef.current.seekTo(e / 100, "fraction");
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime = format(currentTime);

  const totalDuration = format(duration);

  const playerControlProps = {
    playing,
    handlePlayPause,
    handleRewind,
    handleFastForward,
    muted,
    handleMute,
    handleVolumeChange,
    volume,
    setPlaybackRate,
    handlePlaybackRateChange,
    played,
    handleSeek,
    elapsedTime,
    totalDuration,
    duration,
  };
  const { t } = useContext(ThemeContext);
  return (
    <div className="p-1 bg-white rounded-md">
      <div className="video-wrapper" onClick={() => handlePlayPause()}>
        <ReactPlayer
          url={url}
          ref={playerRef}
          playing={playing}
          width="100%"
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          onPlay={()=>setPlaying(true)}
          onPause={()=>setPlaying(false)}
          progressInterval={1000}
          // controls={true}
        />
      </div>
      <PlayerControls {...playerControlProps} />
    </div>
  );
};

export default TeacherVideoPlayer;
