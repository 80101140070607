import { PANES } from "../../constants/constants";
import ChatPane from "./ChatPane/ChatPane";
import PaneContainer from "./components/PaneContainer";
import PaneHeader from "./components/PaneHeader";
import MemoryAnchoringPane from "./MemoryAnchoringPane/MemoryAnchoringPane";
import MemoryAnchoringPaneDEMO from "./MemoryAnchoringPane/MemoryAnchoringPaneDEMO";
import TeacherQuestionsPane from "./TeacherAnswersPane/TeacherAnswersPane";
import { memo } from "react";
import ImgFocused from "./components/ImgFocused";
import TeacherConversationFocused from "./components/TeacherConversationFocused"
import AdaptiveLearningPane from "./AdaptiveLearning/AdaptiveLearningPane";
import { useSelector } from "react-redux";
import InfoPane from "./InfoPane/InfoPane";

const MemoizedChat = memo(ChatPane);

const Pane = () => {
  const pane_visible = useSelector((state) => state.app.general.pane_visible);
  const active_pane = useSelector((state) => state.app.general.active_pane);
  const demo_client = useSelector((state) => state.app.general.demo_client);
  const image_focused = useSelector((state) => state.app.current.image_focused);

  if (!pane_visible) return <></>;
  return (
    <>
      <PaneContainer>
        <ImgFocused />
        {!image_focused && <TeacherConversationFocused />}
        <PaneHeader />
        {active_pane === PANES.CHAT && <MemoizedChat />}
        {active_pane === PANES.TEACHER_ANSWERS && <TeacherQuestionsPane />}
        {active_pane === PANES.MEMORY_ANCHORING &&
          (demo_client ? <MemoryAnchoringPaneDEMO /> : <MemoryAnchoringPane />)}
        {active_pane === PANES.ADAPTIVE_LEARNING && <AdaptiveLearningPane />}
        {active_pane === PANES.INFO && <InfoPane />}
      </PaneContainer>
    </>
  );
};

export default Pane;
