export const getLastMessages = (chat_data_array, quantity, timestamp) => {
  let filtered_chat_data_array = chat_data_array.filter((x) => x.external_id);
  filtered_chat_data_array = filtered_chat_data_array.filter(
    (x) => x.timestamp < timestamp
  );
  filtered_chat_data_array = filtered_chat_data_array.sort((a, b) => {
    return a.timestamp - b.timestamp;
  });
  filtered_chat_data_array = filtered_chat_data_array.slice(-1 * quantity);
  //console.log("filtered_chat_data_array", filtered_chat_data_array);
  return filtered_chat_data_array.map((item) => {
    return item["external_id"];
  });
};
