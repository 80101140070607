import { useDispatch, useSelector } from "react-redux";
import { useState, useContext } from "react";
import { FiX, FiSend } from "react-icons/fi";
import { HiReply } from "react-icons/hi";
import ReactPlayer from 'react-player'
import {
  XsetCurrentTeacherConversation,
  XrespondToTeacher,
} from "../../../appSlice";
import { Input } from "antd";
import { ThemeContext } from "../../ThemeProvider";
import TeacherConversationFocusedMessage from "./TeacherConversationFocusedMessage";

const TeacherConversationFocused = ({}) => {
 const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const teacher_conversation = useSelector(
    (state) => state.app.current.teacher_conversation
  );
  const is_teacher_conversation_closed = useSelector(
    (state) => state.app.current.teacher_conversation_closed
  );
  const [answerMode, setAnswerMode] = useState(false);
  const [userInputValue, setUserInputValue] = useState(null);

  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);

  const handleSendMessage = () => {
    dispatch(
      XrespondToTeacher({
        external_previous_message_id: teacher_conversation[0].external_id,
        content: userInputValue,
      })
    );
    setUserInputValue("");
  };

  const test_text =
    "__Schéma représentant les zones du circuit de la récompense activées dans le cerveau lors de la recherche du plaisir sexuel__ ![](https://clientscoursesimages.s3.eu-west-3.amazonaws.com/sn20fondamentaux_1.pdf-53-229125949807.jpg)";
  const suffix = (
    <FiSend
      style={{
        color: userInputValue
          ? themeColors.primary.base
          : themeColors.primary.extralight,
      }}
      className="text-base ml-1 -mr-2 cursor-pointer"
      onClick={handleSendMessage}
    />
  );

  if (!teacher_conversation) return <></>;
  return (
    <div className="absolute inset-0 bg-gray-500 bg-opacity-70 z-20 rounded-xl">
      <div className="absolute bg-white inset-8 z-30 rounded-xl">
        <div className="flex flex-col h-full">
          <div
            className="flex border-b-2 p-2 items-center text-md"
            style={{
              color: themeColors.primary.base,
            }}
          >
            <div
              className="border rounded-full p-1 cursor-pointer ml-auto"
              style={{
                borderColor: themeColors.primary.base,
              }}
              onClick={() => {
                dispatch(
                  XsetCurrentTeacherConversation({
                    current_conversation: null,
                    external_conversation_id: null,
                  })
                );
                setAnswerMode(false);
              }}
            >
              <FiX />
            </div>
          </div>
          <div className="flex flex-grow flex-col-reverse m-2 gap-2 overflow-auto invisible-scrollbar">
            {teacher_conversation?.map((message, i) => (
              <TeacherConversationFocusedMessage message={message} key={i}/>
            ))}
          </div>
          {answerMode ? (
            <div
              className="mb-1 flex items-center placeholder-white text-base text-gray-400 border-t-2"
              style={{ minHeight: "36px" }}
            >
              <Input
                value={userInputValue}
                placeholder={t("respond-to-teacher")}
                bordered={false}
                allowClear
                onChange={(e) => setUserInputValue(e.target.value)}
                onPressEnter={handleSendMessage}
                suffix={suffix}
                className="mr-2"
                autoFocus
                size="large"
              />
            </div>
          ) : (
            <div className="flex flex-col">
              {is_teacher_conversation_closed && (
                <div className="flex text-center mx-auto w-1/2 text-gray-500 text-2xs p-4">
                  {t("conversation-closed")}
                </div>
              )}
              <div
                className="flex gap-3 items-center mx-auto mb-2 text-xs text-white rounded-md px-4 py-2 cursor-pointer"
                style={{ backgroundColor: themeColors.primary.base }}
                onClick={() => setAnswerMode(true)}
              >
                {t("reply")}
                <HiReply />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherConversationFocused;
