import { forwardRef, useEffect, useRef, useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ChatMessageItemBob from "./components/ChatMessageItemBob";
import ChatMessageItemUser from "./components/ChatMessageItemUser";
import ChatMessageItemAnswer from "./components/ChatMessageItemAnswer";
import RelatedQuestions from "./components/RelatedQuestions";
import ChatOfferAskTeacher from "./components/ChatOfferAskTeacher";
import ChatMessageTeacherQuestionEdit from "./components/ChatMessageTeacherQuestionEdit";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { XgetBobChatHistory } from "../../../../../appSlice";
import { Spin } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../../../assets/chat-typing-indicator.json";
import { ThemeContext } from "../../../../ThemeProvider";

const ChatMessageItem = forwardRef(
  (
    {
      test,
      needScrollFromMessageOrigin,
      sequence_id,
      dataArrayIndex,
      user,
      chat,
      related_questions,
      answer,
      focusOnChatInput,
      timestamp,
      originalTimestamp,
      origin,
      from_history,
    },
    olderMessageScrollRef
  ) => {
    if (chat.type === "question_edit") {
      return (
        <ChatMessageTeacherQuestionEdit
          needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          test={test}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          question={chat.original_question}
          focusOnChatInput={focusOnChatInput}
          originalTimestamp={originalTimestamp}
          origin={origin}
          sequence_id={sequence_id}
          is_from_history={from_history}
        />
      );
    }
    if (chat.type === "answer") {
      return (
        <ChatMessageItemAnswer
          test={test}
          needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          associated_question={chat.associated_question}
          original_question={chat.original_question}
          answer={answer}
          dataArrayIndex={dataArrayIndex}
          ref={olderMessageScrollRef}
          is_from_history={from_history}
        />
      );
    }
    if (chat.type === "related-questions") {
      return (
        <RelatedQuestions
          test={test}
          needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          related_questions={related_questions}
          original_question={chat.original_question}
          is_from_history={from_history}
        />
      );
    }
    if (user && parseInt(user.userID) > 0) {
      return (
        <ChatMessageItemUser
          test={test}
          needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          chat={chat}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          is_from_history={from_history}
        />
      );
    }
    if (user.userID === "0") {
      return (
        <ChatMessageItemBob
          test={test}
          needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          chat={chat}
          ref={olderMessageScrollRef}
          dataArrayIndex={dataArrayIndex}
          is_from_history={from_history}
        />
      );
    }
    if (user.userID === "-1") {
      // BOB CAN'T ANSWER
      // MESSAGE FOR USER
      if (chat.text) {
        return (
          <ChatMessageItemBob
            test={test}
            needScrollFromMessageOrigin={needScrollFromMessageOrigin}
            chat={chat}
            ref={olderMessageScrollRef}
            dataArrayIndex={dataArrayIndex}
            is_from_history={from_history}
          />
        );
      }
      // Offer to ask teacher question
      if (!chat.text) {
        return (
          <ChatOfferAskTeacher
            test={test}
            needScrollFromMessageOrigin={needScrollFromMessageOrigin}
            sequence_id={sequence_id}
            ref={olderMessageScrollRef}
            dataArrayIndex={dataArrayIndex}
            originalQuestion={chat.original_question}
            timestamp={timestamp}
            focusOnChatInput={focusOnChatInput}
            is_from_history={from_history}
          />
        );
      }
    }

    return <div></div>;
  }
);

const TypingMessage = () => {
  const [displaying_timeout, setDisplayingTimeout] = useState(true);

  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );

  const { t } = useContext(ThemeContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setDisplayingTimeout(true);
    setTimeout(() => {
      setDisplayingTimeout(false);
    }, 15000);
  }, [chat_data_array]);

  if (displaying_timeout) {
    return (
      <div className="flex flex-col items-start w-9/12">
        <div
          className="flex flex-col rounded-tl-sm rounded-lg pt-2"
          style={{
            backgroundColor: themeColors.answer_background.base,
            width: "fit-content",
          }}
        >
          <Lottie
            options={defaultOptions}
            height={35}
            width={70}
            isStopped={false}
            isPaused={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start w-9/12">
      <div
        className="flex flex-col rounded-tl-sm rounded-lg p-3"
        style={{
          backgroundColor: themeColors.answer_background.base,
          width: "fit-content",
        }}
      >
        {t("bot-out-of-order")}
      </div>
    </div>
  );
};

const ChatMessages = ({ focusOnChatInput }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.chat.loading);
  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );
  const older_message_index = useSelector(
    (state) => state.app.chat.older_message_index
  );

  const bottomScrollRef = useRef(null);
  const olderMessageScrollRef = useRef(null);

  const needScrollFromMessageOrigin = (is_from_history) => {
    if (!loading) {
      if (is_from_history && chat_data_array.length > 25) {
        console.log("scroll to older");
        olderMessageScrollRef.current?.scrollIntoView(true);
      } else {
        console.log("scroll to newer");
        setTimeout(() => {
          bottomScrollRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
    }
  };
  const test = () => {
    console.log("test called");
  };
  /*useEffect(() => {
    if (
      chat_data_array[chat_data_array.length - 1].timestamp > lastMessageDate
    ) {
      //console.log("scroll to bottom new message");
      //scrollToBottom();
      setLastMessageDate(chat_data_array[chat_data_array.length - 1].timestamp);
    } else if (loading) {
      console.log("scroll to older message");
      olderMessageScrollRef.current?.scrollIntoView(true);
      setTimeout(() => {
        olderMessageScrollRef.current?.scrollIntoView(true);
      }, 100);
    }
  }, [chat_data_array]);*/

  /*useEffect(() => {
    console.log("scroll to bottom init");
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }, []);*/

  const handleOnScroll = (e) => {
    if (e.target.scrollTop === 0 && !loading) {
      dispatch(XgetBobChatHistory());
    }
  };

  return (
    <div
      id="chatMessages"
      className="invisible-scrollbar absolute bottom-0 prose-xs px-2 pl-2 pt-5 my-2 max-h-full w-full overflow-auto"
      onScroll={handleOnScroll}
    >
      {loading && (
        <div className="flex items-center">
          <div className="m-auto">
            <Spin />
          </div>
        </div>
      )}
      <TransitionGroup className="flex flex-col gap-2">
        {chat_data_array?.map((item, i) => (
          <CSSTransition key={i} timeout={500} classNames="chat">
            <ChatMessageItem
              test={test}
              needScrollFromMessageOrigin={needScrollFromMessageOrigin}
              ref={
                i === chat_data_array.length - older_message_index
                  ? olderMessageScrollRef
                  : null
              }
              {...item}
              key={i}
              dataArrayIndex={i}
              focusOnChatInput={focusOnChatInput}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {parseInt(chat_data_array[chat_data_array.length - 1].user.userID) ===
        1 && <TypingMessage />}
      <div ref={bottomScrollRef} />
    </div>
  );
};

export default ChatMessages;
