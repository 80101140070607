import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import AnswerRender from "../../../../components/AnswerRender";

const ChatMessageItemBob = forwardRef(
  (
    { chat, dataArrayIndex, needScrollFromMessageOrigin, is_from_history },
    olderMessageScrollRef
  ) => {
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    useEffect(() => {
      needScrollFromMessageOrigin(is_from_history);
    }, []);
    const commonClassNames = "flex flex-col rounded-tl-sm rounded-lg p-3";
    if (chat.text) {
      return (
        <div
          key={dataArrayIndex}
          className="flex flex-col items-start w-9/12"
          ref={olderMessageScrollRef}
        >
          <div
            className={`${commonClassNames}`}
            style={{
              backgroundColor: themeColors.answer_background.base,
              width: "fit-content",
            }}
          >
            <AnswerRender text={chat.text} />
          </div>
        </div>
      );
    }
  }
);
export default ChatMessageItemBob;
