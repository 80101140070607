import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import ReactMarkdown from "react-markdown";
import RemarkMathPlugin from "remark-math";
import CodeBlock from "./CodeBlock";
// import "./_markdown-render.scss";
import "./markdown-render.css";

const ProcessImage = (props) => {
  if (props.src.match(/^https*.*(png|jpg|jpeg)$/) != null) {
    return <img src={props.src} alt={props.alt} />;
  }
  return null;
};

const ProcessLink = (props) => {
  if (props.href.match(/^https*/) != null) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }
  if (props.children.length > 1) return null;
  return <span>{props.children}</span>;
};
const MdRender = ({ compact, renderers, source, noEllipsisFilter }) => {
  const settings = {
    escapeHtml: false, // enable html rendering
    allowDangerousHtml: true,
    plugins: [RemarkMathPlugin],
    renderers: {
      ...renderers,
      code: CodeBlock,
      math: (props) => <BlockMath math={props.value} />,
      inlineMath: (props) => <InlineMath math={props.value} />,
      link: ProcessLink,
      linkReference: ProcessLink,
      image: ProcessImage,
    },
  };

  //   async componentDidMount() {
  //     if (this.props.getOutline != null) {
  //       this.$content = $(findDOMNode(this.content));
  //     }
  //   }

  // console.log({ ReactMarkdown });
  // console.log(ReactMarkdown.renderers);
  let src = source;
  if (source) {
    src = src
      .replace(/\\+/g, "\\")
      .replace(/\[\\highlight]/g, "")
      .replace(/\[highlight]/g, "");
  }

  if (!noEllipsisFilter) {
    src = src.replace(/\n\.\.\.$/, "").replace(/\.\.\./g, "\n\n---\n");
  }

  return (
    <div
      className={`markdown-render ${compact ? "markdown-render-compact " : ""}`}
    >
      <ReactMarkdown source={src} {...settings} />
    </div>
  );
};

// this is new
export default MdRender;
