import { useSelector } from "react-redux";
import { Tooltip } from "antd";

const IconButton = ({ icon, handleClick, active, badgeValue, tooltip }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const external_course_id = useSelector((state)=>state.app.id.external_course)
  const pane_visible = useSelector((state)=>state.app.general.pane_visible)
  return (
    <Tooltip
      title={tooltip}
      mouseEnterDelay={1}
      arrowPointAtCenter
      placement="topRight"
    >
      <div
        className="flex relative text-white cursor-pointer"
        onClick={handleClick}
      >
        <div className={`${pane_visible ? "hover:opacity-100" : "hover:opacity-40"} ${(!active && pane_visible) && "opacity-40"}`}>
          {icon}
        </div>

        {badgeValue > 0 && (
          <div
            style={{
              backgroundColor:
              external_course_id === "750bc098428c7d" || external_course_id === "0ef7903bfd0677"
                  ? "#ae2573"
                  : external_course_id === "def36ba546e485"
                  ? "#007681"
                  : themeColors.primary.strong,
            }}
            className={`h-5 w-5 rounded-full flex items-center justify-center text-xs font-medium z-50 absolute left-3 bottom-3 text-white`}
          >
            {badgeValue}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default IconButton;
