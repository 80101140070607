import { useContext } from "react";
import { Table, Steps } from "antd";
import { HiCheck, HiX } from "react-icons/hi";
import { FiLoader, FiAward, FiAlertTriangle } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { DEMOsetJ } from "../../../demoSlice";
import { ThemeContext } from "../../ThemeProvider";
const { Step } = Steps;

const TestStatus = ({ value }) => {
  if (value) {
    return (
      <div className="flex flex-col items-center text-lg">
        {value.status === "success" ? (
          <HiCheck className="text-green-700" />
        ) : value.status === "failure" ? (
          <HiX className="text-red-700" />
        ) : value.status === "alert" ? (
          <FiAlertTriangle className="text-red-700" />
        ) : value.status === "award" ? (
          <FiAward className="text-green-700" />
        ) : (
          value.status === "current" && (
            <FiLoader className="animate-spin spin-slow" />
          )
        )}
        {false && value.status !== "" && (
          <div className="text-3xs">{value.j}</div>
        )}
      </div>
    );
  } else return "";
};

const MemoryAnchoringDEMOPanel = () => {
  const { t } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const j = useSelector((state) => state.demo.j);
  const overview = useSelector((state) => state.demo.overview);

  const getCurrentJIndex = () => {
    if (j === 0) {
      return 0;
    } else if (j === 2) {
      return 1;
    } else if (j === 7) {
      return 2;
    } else if (j === 9) {
      return 3;
    } else if (j === 11) {
      return 4;
    } else if (j === 30) {
      return 5;
    } else return 5;
  };

  const getColumns = () => {
    return [
      {
        title: "Concept",
        dataIndex: "concept",
        key: "concept",
      },
      {
        title: t("learning-date"),
        dataIndex: "acquisition",
        key: "acquisition",
        render: (value) => (
          <div className="flex flex-col items-center">{value}</div>
        ),
      },
      {
        title: " test",
        dataIndex: "1",
        key: "1",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: " test",
        dataIndex: "2",
        key: "2",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: " test",
        dataIndex: "3",
        key: "3",
        render: (value) => <TestStatus value={value} />,
      },
      {
        title: " test",
        dataIndex: "4",
        key: "4",
        render: (value) => <TestStatus value={value} />,
      },
    ];
  };
  return (
    <div
      className="flex flex-col gap-2 bg-gray-100 m-4 rounded-lg p-2"
      style={{ maxWidth: "560px" }}
    >
      <div className="flex flex-col gap-2 rounded-t-md bg-white p-2">
        <div className="text-semibold text-base">Demo</div>
        <div className="whitespace-pre-wrap">{t("demo-description")}</div>
      </div>
      <div className="flex items-center bg-white">
        <Table
          className="w-full"
          columns={getColumns()}
          dataSource={overview}
          pagination={false}
        />
      </div>
      <div className="flex items-center rounded-b-sm bg-white py-4 p-2">
        <div className="text-semibold text-xs">Timeline</div>
        <div className="flex items-center pt-2">
          <Steps
            size="small"
            progressDot
            current={getCurrentJIndex()}
            className="w-full"
          >
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(0))}
            />
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(2))}
            />
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(7))}
            />
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(9))}
            />
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(11))}
            />
            <Step
              className="cursor-pointer"
              onClick={() => dispatch(DEMOsetJ(30))}
            />
          </Steps>
        </div>
      </div>
    </div>
  );
};
export default MemoryAnchoringDEMOPanel;
