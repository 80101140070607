import { useEditor, EditorContent } from "@tiptap/react";

import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Image from '@tiptap/extension-image';
import Strike from '@tiptap/extension-strike';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import History from '@tiptap/extension-history';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Link from '@tiptap/extension-link';

import { BlockMathViewer } from "./tiptapExtensions/blockMathExtension";
import InlineMath from './tiptapExtensions/inlineMathExtension';

import { useEffect } from "react";

export default function TipTapRender({ source }) {
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(source);
    }
  }, [source]);
  const editor = useEditor({
    extensions: [
      Bold,
      Italic,
      Image.configure({ inline: true }),
      Strike,
      Document,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false,
      }),
      BulletList,
      OrderedList,
      ListItem,
      Code,
      CodeBlock, 
      History,
      InlineMath, 
      BlockMathViewer,
      Subscript,
      Superscript
    ],
    editable: false,
    content: source || "<></>",
  });
  if (!editor) {
    return null;
  }
  return (
    <div className="">
      <EditorContent editor={editor} />
    </div>
  );
}
