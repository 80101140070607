const resources = {
  HTML: {
    questions: [
      {
        question: "Comment bien indenter ?",
        answer:
          "L'indentation c'est un espace libre laissé au début d'une ligne de code, une marge à gauche en quelque sorte. Vous remarquerez que lorsque l'on imbrique des balises dans d'autres balises, nous les indentons chaque fois un peu plus. Il vous suffit de laisser un espace au début de votre ligne avant votre code.",
        state: 0,
      },
      {
        question: "Comment créer une page HTML ?",
        answer:
          "En condition réel, vous pouvez créer une page html depuis Microsoft Word. Dans le volet de tâche Nouveau Document, cliquez sur Page Web vierge sous Nouveau.Dans le menu Fichier, cliquez sur Enregistrer. ... Dans la zone Nom du fichier, tapez un nom de votre choix pour le nouveau document, puis cliquez sur Enregistrer.",
        state: 0,
      },
      {
        question: "Comment inclure une feuille de style en HTML ?",
        answer:
          "Il y a deux méthodes principales pour placer une feuille de style. La première consiste à placer la feuille de style dans l'entête de document. La seconde consiste à placer la feuille de style dans un fichier séparé, et à y faire référence dans l'entête du document.",
        state: 0,
      },
      {
        question: "Comment faire un espace en HTML ?",
        answer:
          "En HTML, si dans votre texte, vous faites un espace avec la barre d'espace, vous aurez un espace. Si vous en faites 4, 10 ou 100, vous n'aurez qu'un espace dans la page web. Tapez &nbsp; pour avoir une espace insécable.",
        state: 0,
      },
      {
        question: "Comment mettre des ancres en HTML ?",
        answer:
          "Créer une ancre est facile : il suffit d'attribuer à l'élément vers lequel on veut pouvoir pointer un identifiant (avec l'attribut HTML id ) et d'y associer un lien débutant par le caractère dièse # , suivi du nom de cet identifiant.",
        state: 0,
      },
      {
        question: "Comment écrire un texte en HTML ?",
        answer:
          "Pour rédiger un paragraphe, tapez la balise d'ouverture ( <p> ), puis le texte de votre choix et enfin la balise de fermeture du paragraphe ( </p> ). Chaque ligne de code occupe une ou plusieurs lignes. Quand vous changez d'instruction, appuyez sur la touche Entrée .",
        state: 0,
      },
      {
        question: "Pourquoi utiliser HTML ?",
        answer:
          "Le HTML sert à définir les différents éléments d'une page, à leur donner du sens. ... Le HTML va donc créer la structure des pages tandis que le CSS va nous permettre de modifier l'apparence des contenus de la page.",
        state: 0,
      },
      {
        question: "Quand utiliser HTML ?",
        answer:
          "Le HTML ou HyperText Markup Language est un langage très populaire et incontournable : c'est lui qui permet de créer les pages web ! Ce n'est pas un langage de programmation à proprement parler, mais plutôt un langage de balisage (ou marquage).",
        state: 0,
      },
      {
        question: "Quel est le contenu d'un document HTML ?",
        answer:
          "Un document HTML commence par la balise <html> et finit par la balise </html>. Il contient également un en-tête décrivant le titre de la page, puis un corps dans lequel se trouve le contenu de la page. ... Le corps est délimité par les balises <body> et </body>.",
        state: 0,
      },
      {
        question:
          "Quelle propriété permet de se référer au contenu de la balise HTML ?",
        answer:
          "Le contenu HTML textuel permet d'organiser des blocs ou des sections de contenu entre la balise ouvrante <body> et la balise fermante </body> . ... L'élément HTML <dd> fournit la description, la définition ou la valeur du terme précédent ( <dt> ) dans une liste de description ( <dl> ).",
        state: 0,
      },
      {
        question:
          "Quels sont les éléments HTML permettant de prendre en charge l'affichage de données textuelles sur une page ?",
        answer:
          "Le contenu HTML textuel permet d'organiser des blocs ou des sections de contenu entre la balise ouvrante <body> et la balise fermante </body> . Ces éléments sont cruciaux pour l'accessibilité et le référencement car ils permettent d'identifier le sens du contenu.",
        state: 0,
      },
    ],
    id: "53",
  },
  CSS: {
    questions: [
      {
        question: "Qu'est ce qu'une feuille de style CSS ?",
        answer:
          "Les feuilles de style en cascade, généralement appelées CSS de l'anglais Cascading Style Sheets, forment un langage informatique qui décrit la présentation des documents HTML et XML. Les standards définissant CSS sont publiés par le World Wide Web Consortium.",
        state: 0,
      },
      {
        question: "Qu'est ce qu'une classe CSS ?",
        answer:
          "Une classe est un attribut appliqué à un groupe d'élément HTML. Par exemple, un <div class=”container”> est une boîte de type container. Ainsi l'on peut attribuer des propriétés à la classe dans le fichier CSS.",
        state: 0,
      },
      {
        question: "Où placer le code CSS dans HTML ?",
        answer:
          "Il est possible d'écrire du code CSS dans l'entete d'un document HTML, dans la balise <head>. Il suffit pour cela de l'encadrer par une balise <style> ayant pour attribut type='text/css'.",
        state: 0,
      },
      {
        question: "Comment fonctionne le langage CSS ?",
        answer:
          "En deux mots : Le langage CSS ou Cascading Style Sheets est utilisé pour définir l'aspect futur de votre site: exemple la couleur du fond de la page ou le type de police. Plus concrètement : le CSS (ou feuille de style), c'est un petit fichier (exemple 'style.",
        state: 0,
      },
      {
        question: "A quoi sert le CSS ?",
        answer:
          "Le CSS est un langage de mise en forme des documents. Le CSS peut être utilisé pour une mise en forme élémentaire des documents — par exemple changer la couleur et la taille des titres et des liens.",
        state: 0,
      },
      {
        question: "Comment commencer un CSS ?",
        answer:
          "Ouvrez votre fichier index.html et copiez la ligne suivante quelque part au sein de l'élément head (c'est-à-dire entre les balises <head> et </head> ) : <link href='styles/style.css' rel='stylesheet' type='text/css'>Sauvegardez index.html puis chargez-le dans votre navigateur. ",
        state: 0,
      },
      {
        question: "Comment définir une classe CSS ?",
        answer:
          "Définir une classe revient à donner un nom de style à un élément HTML et à préciser les propriétés de style pour ce nom dans notre feuille de style. Dans notre feuille de style, nous allons définir cette classe ainsi : . nom_de_ma_classe { liste des propriétés ici }.",
        state: 0,
      },
      {
        question: "C'est quoi le langage CSS ?",
        answer:
          "CSS est l'acronyme de « Cascading Style Sheets » ce qui signifie « feuille de style en cascade ». Le CSS correspond à un langage informatique permettant de mettre en forme des pages web (HTML ou XML).",
        state: 0,
      },
      {
        question: "Pourquoi utilise-t-on le langage CSS ?",
        answer:
          "Le CSS permet de modifier la forme d'une balise HTML, d'une classe (nom que l'on peut donner à plusieurs balises pour les différencier), ou d'un élément en particulier.",
        state: 0,
      },
      {
        question: "Comment peut s'appeler une Feuille de style ?",
        answer:
          "Plus concrètement, le CSS (ou feuille de style), c'est un petit fichier (exemple « style. css ») dans lequel vous allez définir l'aspect futur de votre site.",
        state: 0,
      },
      {
        question: "Quel est l'intérêt principal du CSS ?",
        answer:
          "Le CSS permet à l'utilisateur de personnaliser une page web Aujourd'hui, de nombreux sites web permettent à l'utilisateur de changer la mise en page d'un site sans modifier le contenu. Les feuilles de styles qui sont stockées en externe permettent à l'utilisateur d'effectuer les changements requis par eux-memes.",
        state: 0,
      },
      {
        question: "Quelle est le rôle de la Feuille de style ?",
        answer:
          "Les feuilles de styles (en anglais 'Cascading Style Sheets', abrégé CSS) sont un langage qui permet de gérer la présentation d'une page Web. Le langage CSS est une recommandation du World Wide Web Consortium (W3C), au même titre que HTML ou XML.",
        state: 0,
      },
      {
        question: "Quelle est la fonction principale d'un fichier CSS ?",
        answer:
          "CSS est l'acronyme de « Cascading Style Sheets » ce qui signifie « feuille de style en cascade ». Le CSS correspond à un langage informatique permettant de mettre en forme des pages web (HTML ou XML). Ce langage est donc composé des fameuses « feuilles de style en cascade » également appelées fichiers CSS.",
        state: 0,
      },
    ],
    id: "54",
  },
  Balises: {
    questions: [
      {
        question: "C'est quoi les balises HTML ?",
        answer:
          "Les balises HTML sont les éléments de base du codage HTML. Elles sont utilisées pour mettre en forme un texte, structurer et hiérarchiser le contenu d'une page.",
        state: 0,
      },
      {
        question: "Comment centrer du texte en HTML ?",
        answer:
          "<center> : l'élément de texte centré L'élément <center> est un élément de bloc qui contient des paragraphes et d'autres éléments de type bloc ou en ligne. Le contenu entier de cet élément est centré horizontalement au sein de son conteneur parent (généralement l'élément <body> ).",
        state: 0,
      },
      {
        question: "Comment faire des balises HTML ?",
        answer:
          "La balise HTML commence toujours par “<” et se termine par “>”. Par exemple, le code “H3” signifie “titre de niveau 3” en langage HTML. Si vous souhaitez nommer un sous-titre “Apprendre la rédaction web”, vous devrez taper <H3>Apprendre la rédaction web</H3>.",
        state: 0,
      },
      {
        question: "Comment utiliser la balise h1 ?",
        answer:
          "La balise h1 Le titre <h1> est le plus important et le plus visible de votre page, c'est pour cette raison qu'il est associé au titre principal. C'est le premier à apparaître. Du fait de son importance, il est recommandé d'insérer un mot-clé car Google lui donnera plus d'importance.",
        state: 0,
      },
      {
        question: "Comment utiliser la balise IMG ?",
        answer:
          "Pour mettre une image simple sur une page web, nous utiliserons l'élément <img> . C'est un élément vide (ce qui signifie qu'il ne contient ni texte ni balise de fermeture) qui demande au moins un attribut pour fonctionner — src (souvent appelé par son nom entier: source).",
        state: 0,
      },
      {
        question: "Comment utiliser la balise style ?",
        answer:
          "L'élément <style> peut être inclus dans l'élément <head> ou dans l'élément <body> du document et les styles seront appliqués. Toutefois, il est recommandé de placer les styles dans l'élément <head> afin de clairement séparer la présentation du contenu autant que possible.",
        state: 0,
      },
      {
        question: "Quel est la balise principale responsable de l'affichage ?",
        answer:
          "La balise <head> délimite l'en-tête de la page dont on vient de parler. On y trouve des informations qui ne seront pas affichées directement dans la zone de rendu du navigateur. Par exemple le titre de la page, le lien vers la feuille de style, une description et des mots clés, etc...",
        state: 0,
      },
      {
        question: "Quel est le rôle de la balise HEAD ?",
        answer:
          "L'élément HTML <head> fournit des informations générales (métadonnées) sur le document, incluant son titre et des liens ou des définitions vers des scripts et feuilles de style.",
        state: 0,
      },
      {
        question: "Quel est le rôle des balises hn ?",
        answer:
          "En référencement naturel, Hn décrit une balise utilisée pour le titrage et sous-titrage d'un contenu web (en complément de la balise Title). Elle se décline sous la forme h1, h2, h3, etc., jusqu'à h6, et entoure les différents titres et sous-titres d'un document en les hiérarchisant sur plusieurs niveaux (6 au total).",
        state: 0,
      },
      {
        question:
          "Quelle balise est utilisée pour représenter une ligne de valeurs dans un tableau ?",
        answer:
          "La balise <tr> permet d'ajouter une ligne à un tableau. Elle est directement déclarée dans la balise <table>. Il y a donc autant de balises <tr> que de lignes.",
        state: 0,
      },
      {
        question: "Quelle balise permet de créer une liste avec des numéros ?",
        answer:
          "Pour créer une liste numérotée, le HTML utilise les balises OL et LI. Deux options nous sont offertes : le type de numérotation : en chiffres arabes (option par défaut), en chiffres romains (majuscules ou minuscules), ou en lettres (majuscules ou minuscules).",
        state: 0,
      },
      {
        question: "Quelle balise permet de créer une liste non-ordonnée ?",
        answer:
          "Les listes non-ordonnées ul (listes à puces) Pour créer une liste en HTML, on utilise les balises HTML <ul></ul> et pour remplir cette liste d'éléments on utilise les balises <li></li> pour définir un élément de la liste.",
        state: 0,
      },
      {
        question: "Pourquoi utiliser la balise <span></span> ?",
        answer:
          "Span sert surtout à associer un style à une partie d'un texte tandis que div sert à agencer le contenu de la page. Les deux balises doivent avoir une balise de début et de fin, la forme réduite n'est pas reconnue évidemment. La balise peut être vide.",
        state: 0,
      },
    ],
    id: "55",
  },
  Images: {
    questions: [
      {
        question: "Comment changer la position d'une image en HTML ?",
        answer:
          "On utilise l'élément <IMG> pour placer les images. Deux attributs sont obligatoires : L'attribut src pour spécifier le nom du fichier image à charger. Les fichiers image doivent impérativement être aux formats GIF ou JPG .",
        state: 0,
      },
      {
        question: "Comment faire un lien image en HTML ?",
        answer:
          "Pour faire un lien sur une portion de texte ou sur une image, ou bien faire un lien pointant vers une autre ressource sur le Web, il suffit d'enserrer la portion de texte concernée ou la balise <img> appelant l'image concernée entre deux balises <a>, une ouvrante et une fermante, et renseigner l'attribut href de la balise <a> avec l'URL de ...",
        state: 0,
      },
      {
        question: "Comment insérer une balise image ?",
        answer:
          "On insère une image avec la balise <img /> . Elle doit obligatoirement comporter au moins ces deux attributs : src (nom de l'image) et alt (courte description de l'image). Si une image illustre le texte (et n'est pas seulement décorative), il est conseillé de la placer au sein d'une balise <figure> .",
        state: 0,
      },
      {
        question: "Comment insérer une image en HTML ?",
        answer:
          "La balise HTML qui vous permettra d'insérer une image est la suivante : <img/> . Malgré le fait qu'elle peut contenir un large panel d'attributs, le seul et unique essentiel à son fonctionnement est l'attribut src . Cet attribut va vous permettre d'indiquer le chemin (absolu ou relatif) de votre image.",
        state: 0,
      },
      {
        question: "Comment mettre une image à coter d'un texte HTML ?",
        answer:
          "Toujours d'abord faire un p avec l'image ensuite un p avec le texte. Pour aligner une image à droite par rapport à un texte, il suffit de faire un float:right. Pour aligner une image à gauche par rapport à un texte, un float:left.",
        state: 0,
      },
      {
        question: "Comment mettre une image en Arrière-plan en HTML ?",
        answer:
          "Mettre une image de fond dans une page web est un exercice très répandu. Avec tout éditeur de page HTML , l'option classique est de générer un code du type : <body background='images/image_de_fond'>.",
        state: 0,
      },
      {
        question:
          "Quel attribut permet d'indiquer où se trouve l'image à insérer ?",
        answer:
          "La balise doit être accompagnée de deux attributs obligatoires : src : il permet d'indiquer où se trouve l'image que l'on veut insérer. Vous pouvez soit mettre un chemin absolu (ex. : http://www.site.com/fleur.png ), soit mettre le chemin en relatif (ce qu'on fait le plus souvent).",
        state: 0,
      },
      {
        question: "Quelle est la balise fermante de IMG ?",
        answer:
          "Il y a deux types de balise : les balises ouvrantes '<balise>' et les balises fermantes '</balise>'. Dans la plupart des cas, on met quelque chose entre les deux (par exemple : <p>Ceci est un paragraphe</p>). Cependant, quelquefois, comme c'est le cas pour la balise img, il n'y a rien a mettre entre les deux balises.",
        state: 0,
      },
      {
        question: "Quels attributs sont utilisés avec l'élément IMG ?",
        answer:
          "L'élément <IMG> Deux attributs sont obligatoires : L'attribut src pour spécifier le nom du fichier image à charger. Les fichiers image doivent impérativement être aux formats GIF ou JPG .",
        state: 0,
      },
      {
        question: "Comment mettre une image CSS ?",
        answer:
          "Il faut donc faire apparaitre l'image dans la structure HTML à l'aide de la balise <img> dûment renseignée avec l'attribut alt correspondant, pour en donner un équivalent textuel.",
        state: 0,
      },
      {
        question: "Comment positionner une image sur CSS ?",
        answer:
          "Pour cela, il suffit d'appliquer la propriété CSS display: block; à votre image. Une fois ces deux prérequis respectés vous pouvez appliquer margin-left: auto; et margin-right: auto; ou simplement margin: auto; à votre image pour la centrer.",
        state: 0,
      },
      {
        question: "Comment positionner une image dans une page HTML ?",
        answer:
          "La balise HTML qui vous permettra d'insérer une image est la suivante : <img/> . Malgré le fait qu'elle peut contenir un large panel d'attributs, le seul et unique essentiel à son fonctionnement est l'attribut src . Cet attribut va vous permettre d'indiquer le chemin (absolu ou relatif) de votre image.",
        state: 0,
      },
    ],
    id: "57",
  },
  Listes: {
    questions: [
      {
        question: "Comment créer une liste en HTML ?",
        answer:
          "Pour créer une liste en HTML, on utilise les balises HTML <ul></ul> et pour remplir cette liste d'éléments on utilise les balises <li></li> pour définir un élément de la liste.",
        state: 0,
      },
      {
        question: "Quels éléments sont nécessaires pour créer une liste HTML ?",
        answer:
          "Les éléments nécessaires sont les balises HTML <ul></ul> et pour remplir cette liste d'éléments on utilise les balises <li></li> pour définir un élément de la liste.",
        state: 0,
      },
      {
        question: "Comment faire une liste à puce ?",
        answer:
          "L'élément HTML <ul> représente une liste d'éléments sans ordre particulier. Il est souvent représenté par une liste à puces.",
        state: 0,
      },
      {
        question:
          "Quelle est la balise qui déclare une liste à puces non ordonnée ?",
        answer:
          "Les balises utilisées sont OL et LI ; La liste à puce ou liste non ordonnée. Il est possible de choisir entre trois types de puce : un rond, un cercle ou un carré -- tous noirs, bien entendu. Les balises utilisées sont UL et LI.",
        state: 0,
      },
      {
        question:
          "Quelle est la balise utiliser pour ajouter une liste avec des numéros ?",
        answer:
          "HTML et CSS Une liste est délimitée par les balises <ol> et </ol> si c'est une liste numérotée (ordered list) ou par <ul> et </ul> si c'est une liste à puces (unordered list).",
        state: 0,
      },
      {
        question:
          "Quelle est la balise utiliser pour ajouter une liste sans numéros ?",
        answer:
          "C'est en faite une liste d'éléments, sans ordre (il n'y a pas de 'premier' ni de 'dernier'). Pour Créer cette liste à puce non on utilise la balise <ul> qu'on ferme avec un </ul>.",
        state: 0,
      },
      {
        question: "Comment créer une liste ordonnée en HTML ?",
        answer:
          "Une liste ordonnée en HTML se crée par l'utilisation des balises <ol></ol> et tout comme pour les listes non-ordonnées vous pouvez remplir les listes ordonnées d'éléments par l'utilisation des balises <li></li>.",
        state: 0,
      },
      {
        question: "Comment faire afficher une liste ordonnée en HTML ?",
        answer:
          "L'élément HTML <ol> représente une liste ordonnée. Les éléments d'une telle liste sont généralement affichés avec un indicateur ordinal pouvant prendre la forme de nombres, de lettres, de chiffres romains ou de points.",
        state: 0,
      },
      {
        question:
          "Comment peut-on faire une liste numérotée et une liste à puce en HTML ?",
        answer:
          "Une liste ordonnée en HTML se crée par l'utilisation des balises <ol></ol> et tout comme pour les listes non-ordonnées vous pouvez remplir les listes ordonnées d'éléments par l'utilisation des balises <li></li>.",
        state: 0,
      },
      {
        question:
          "Quels éléments sont nécessaires pour créer une liste ordonnée HTML ?",
        answer:
          "Il vous suffit d'utiliser les balises <ol></ol>. Par la suite vous pouvez remplir les listes ordonnées d'éléments par l'utilisation des balises <li></li>.",
        state: 0,
      },
      {
        question: "Qu'est ce qu'une balise <ol> en HTML ?",
        answer:
          "L'élément HTML <ol> représente une liste ordonnée. Les éléments d'une telle liste sont généralement affichés avec un indicateur ordinal pouvant prendre la forme de nombres, de lettres, de chiffres romains ou de points.",
        state: 0,
      },
      {
        question: "Comment créer une liste imbriqué en HTML ?",
        answer:
          "La bonne façon de créer une liste imbriquée HTML consiste à l'imbriquer en <ul> tant qu'enfant du <li> auquel il appartient. La liste imbriquée doit se trouver à l'intérieur de l' <li> élément de la liste dans laquelle elle est imbriquée.",
        state: 0,
      },
      {
        question: "Comment créer une liste non-ordonnée en HTML ?",
        answer:
          "Pour créer une liste non-ordonnée, nous allons avoir besoin d'un élément ul (pour « unordered list », ou « liste non-ordonnée » en français) qui va représenter la liste en soi ainsi que d'un élément li (« list item » ou « élément de liste ») pour chaque nouvel élément de liste.",
        state: 0,
      },
      {
        question: "Comment faire apparaître des puces carrées dans une liste ?",
        answer:
          "Pour créer une liste à puces, rien de sorcier, il suffit d'ouvrir une balise <ul> et d'y insérer les différentes entrées dans des balises <li>....De base, il existe trois styles de puces :disc – La puce par défaut.circle – Une puce ronde mais vide à l'intérieur. square – Une puce carrée.",
        state: 0,
      },
      {
        question: "Comment faire une liste à puce en HTML ?",
        answer:
          "Vous connaissez sans doute les listes à puces HTML, celles qui permettent d'ordonner du contenu sur vos sites internet. Pour créer une liste à puces, rien de sorcier, il suffit d'ouvrir une balise <ul> et d'y insérer les différentes entrées dans des balises <li>.",
        state: 0,
      },
      {
        question: "Comment faire une liste à puces en HTML ?",
        answer:
          "Pour créer une liste à puces, il vous faut ouvrir une balise <ul> et d'y insérer les différentes entrées dans des balises <li>.",
        state: 0,
      },
      {
        question: "Comment faire une liste avec des points sur HTML ?",
        answer:
          "Les listes à puces HTML permettent d'ordonner du contenu sur vos sites internet. Pour créer une liste à puces il faut créer une balise <ul> et d'y insérer les différentes entrées dans des balises <li>.",
        state: 0,
      },
      {
        question:
          "Quels éléments sont nécessaires pour créer une liste non ordonnée ?",
        answer:
          "Une liste non-ordonnée est composée de différents éléments. Nous allons avoir besoin d'un élément <ul> qui va représenter la liste en soi ainsi que d'un élément <li> pour chaque nouvel élément de liste.",
        state: 0,
      },
      {
        question: "Qu'est ce qu'une balise <ul> en HTML ?",
        answer:
          "Cette balise nous permets de créer une liste en HTML. On utilise les balises HTML <ul></ul> pour créer la lsite et pour remplir cette liste d'éléments on utilise les balises <li></li> pour définir un élément de la liste.",
        state: 0,
      },
    ],
    id: "60",
  },
  Style: {
    questions: [
      {
        question: "Comment augmenter la taille d'un tableau en HTML ?",
        answer:
          "La hauteur et la largeur d'un cellule La somme des % des hauteurs des lignes doit être égale au % de la hauteur du tableau. On écrira <TD HEIGHT='10%'>, la cellule prendra 10% de la hauteur du tableau.",
        state: 0,
      },
      {
        question: "Comment changer la couleur de la police ?",
        answer:
          "Pour modifier la couleur du texte dans votre code HTML il vous faut utliser certains éléments indispensables. Les balises à utiliser sont < FONT color='nom de la couleur en anglais'> </FONT>. ",
        state: 0,
      },
      {
        question: "Comment changer la couleur de la police en CSS ?",
        answer:
          "Pour cela vous devez utiliser la feuille de style Css color qui permet de spécifier la couleur de texte. Exemple d'écriture d'une couleur de texte en Css : color : crimson; color : rgb(255,0,0); color : hsl(16,100%,50%); color : #FF00FF; Il est possible en Css de mettre des couleurs avec des alpha.",
        state: 0,
      },
      {
        question: "Comment changer la couleur de la police en HTML ?",
        answer:
          "Pour changer la couleur du texte, il faut utiliser la balise < FONT color='red'> </FONT>. Tout ce qui sera compris entre <FONT color='red'> et </FONT> aura la couleur rouge.",
        state: 0,
      },
      {
        question:
          "Quels éléments sont nécessaire pour changer la police en HTML ?",
        answer:
          "Pour changer la police du texte,les balises nécessaires sont < FONT face='nom de la police'> </FONT>.",
        state: 0,
      },
      {
        question: "Comment changer la police en HTML ?",
        answer:
          "Pour changer la police du texte, il faut utiliser la balise < FONT face='arial'> </FONT>. Tout ce qui sera compris entre <FONT face='arial'> et </FONT> sera affiché en Arial.",
        state: 0,
      },
      {
        question: "Comment changer la police HTML CSS ?",
        answer:
          "La propriété font-family permet de changer la police de caractère d'un texte donné. Pour cela, il suffit d'indiquer le nom de la police à utiliser entre guillemets.",
        state: 0,
      },
      {
        question: "Comment changer la taille de la police en HTML ?",
        answer:
          "Pour modifier la taille des caractères, on peut utiliser la balise <font size='x'> ... </font> où x peut prendre, au choix, les valeurs de 1 à 7 ou de -3, -2, -1, 0, +1, +2, +3.",
        state: 0,
      },
      {
        question: "Comment mettre une police en HTML ?",
        answer:
          "On commence donc par introduire, grâce à la commande @font-face, une nouvelle police, que l'on appelera par la suite BonvenoCF-Light, et qui se trouve dans le sous répertoire Fonts (l'extension otf est un acronyme pour 'opentype font').",
        state: 0,
      },
      {
        question: "Où se trouve la balise style ?",
        answer:
          "Balise de fermeture obligatoire. La balise STYLE est déclarée au sein des tags HEAD (balise de type bloc) afin d'encadrer et définir l'ensemble des styles qui seront utilisés dans la page.",
        state: 0,
      },
      {
        question:
          "Quel attribut HTML est utilisé pour ajouter un style dans un élément ?",
        answer:
          "Les attributs HTML class et id sont des attributs dits globaux car on va pouvoir les ajouter dans la balise ouvrante de n'importe quel élément HTML. Ces deux attributs vont être principalement utilisés dans un but de mise en forme : ils vont nous servir à appliquer des styles CSS aux éléments qui vont les contenir.",
        state: 0,
      },
      {
        question:
          "Quel attribut HTML est utilisé pour définir les styles en ligne ?",
        answer:
          "<style> : l'élément d'information de style. L'élément HTML <style> contient des informations de mise en forme pour un document ou une partie d'un document.",
        state: 0,
      },
    ],
    id: "61",
  },
  Tableaux: {
    questions: [
      {
        question:
          "Quelles sont les balises HTML nécessaires pour faire un tableau ?",
        answer:
          "En HTML, un tableau se construit ligne par ligne. Dans chaque ligne ( <tr> ), on indique le contenu des différentes cellules ( <td> ). Schématiquement, un tableau se construit comme à la figure suivante. On a une balise de ligne ( <tr> ) qui englobe un groupe de cellules ( <td> ).",
        state: 0,
      },
      {
        question: "Comment centrer un tableau en HTML ?",
        answer:
          "Avec la mention align='right' vous pouvez aligner un tableau à droite et avec la mention align='center' vous le centrez.",
        state: 0,
      },
      {
        question: "Comment créer un tableau vide en HTML ?",
        answer:
          "En pratique, nous attribuerons toujours un même nombre de td à nos différentes lignes. Si on souhaite laisser une cellule vide, nous nous contenterons d'écrire <td> </td> sans rien écrire entre les balises.",
        state: 0,
      },
      {
        question: "Comment encadrer un tableau en HTML ?",
        answer:
          "Nous vous recommandons donc d'utiliser la propriété CSS border pour ajouter une bordure à vos tables. Pour ajouter une bordure à votre tableau, vous devez définir le <style> de votre tableau. N'oubliez pas d'ajouter également des bordures pour que les balises <th> et <td> aient un tableau complet.",
        state: 0,
      },
      {
        question: "Comment faire des colonnes en HTML ?",
        answer: "Créer une colonne de tableau HTML avec la balise <td> </td>.",
        state: 0,
      },
      {
        question: "Comment faire une bordure dans un tableau HTML ?",
        answer:
          "On peut afficher les bordures des cellules avec l'attribut border placé dans la balise table. On peut modifier l'espacement entre le texte et les bords des cellules avec l'attribut cellpadding placé dans la balise table.",
        state: 0,
      },
      {
        question: "Comment faire un tableau avec des images HTML ?",
        answer:
          "La balise <table> permet de créer un tableau. Il faudra utiliser la balise <tr> pour créer une nouvelle ligne dans le tableau. Entre les balises <tr> et </tr> nous mettrons nos cellules grâce aux balises <td>. Entre les balises <td> et </td> nous mettrons le contenu de la case (texte, image...).",
        state: 0,
      },
      {
        question:
          "Quels éléments peuvent être utilisés pour afficher un tableau dans une page HTML ?",
        answer:
          "L'élément HTML <table> permet de représenter un tableau de données, c'est-à-dire des informations exprimées sur un tableau en deux dimensions.",
        state: 0,
      },
      {
        question: "Comment ajouter une ligne d'en-tête à un tableau ?",
        answer:
          "La ligne d'en-tête se crée avec un balise <tr>, mais les cellules qu'elle contient sont, cette fois, encadrées par des balises <th>  et non pas <td>  ! Comme le nom des cellules est un peu différent pour l'en-tête, il faut penser à mettre à jour le CSS pour lui dire d'appliquer une bordure sur les cellules normales et sur l'en-tête.",
        state: 0,
      },
      {
        question: "Comment ajouter un titre à un tableau ?",
        answer:
          "Un tableau a en théorie toujours besoin d'un titre. La balise <caption>Titre</caption> se place en début de tableau, juste avant l'en-tête. La position du tableau peut être changer avec la propriété CSS 'caption-side'?",
        state: 0,
      },
    ],
    id: "62",
  }
};

export default resources;
