import { text2html } from "../../../libs/textProcess";
import MarkdownRenderWithImagesFocus from "../../MarkDown/MarkdownRenderWithImagesFocus";
import TipTapRender from "../../TipTapRender/TipTapRender";
import { XRegExpTestLb } from "../../../libs/XRegExpLb";

export default function AnswerRender({ html, text }) {
  function shouldDisplayInHTML() {
    if (html) {
      if (html.includes("<block-math")) {
        return true;
      }
      if (html.includes("<inline-math")) {
        return true;
      } 
      if (!text){
        return true
      }
    }
    return false;
  }

  if (shouldDisplayInHTML()) return <TipTapRender source={html} />;
  else if (text?.match(/\$(.*?)\$/))
    return <TipTapRender source={text2html(text)} />;
  else return <MarkdownRenderWithImagesFocus compact source={text} />;
}
