import { useSelector } from "react-redux";
import { PANES } from "../../../constants/constants";

const PaneContent = ({ children }) => {
  const active_pane = useSelector((state) => state.app.general.active_pane);

  return (
    <div
      id="PaneContent"
      className={`invisible-scrollbar relative overflow-auto ${
        active_pane === PANES.TEACHER_ANSWERS ? "mb-2 " : ""
      }`}
    >
      {children}
    </div>
  );
};

export default PaneContent;
