import { FiX, FiDownload, FiPlus, FiMinus } from "react-icons/fi";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { XsetImageFocused } from "../../../appSlice";

const ImgFocused = () => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const image_focused = useSelector((state) => state.app.current.image_focused);

  const dispatch = useDispatch();
  if (!image_focused) return <></>;
  return (
    <div className="absolute inset-0 bg-gray-500 bg-opacity-70 z-10 rounded-xl">
      <div className="absolute bg-white inset-8 z-20 rounded-xl overflow-hidden">
        <TransformWrapper initialScale={0.8} minScale={0.8} centerOnInit={true}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <div className="flex flex-col h-full">
              <div
                className="flex border-b-2 p-3 items-center text-md"
                style={{
                  color: themeColors.primary.base,
                }}
              >
                <div
                  className="border rounded-full p-1 cursor-pointer"
                  style={{
                    borderColor: themeColors.primary.base,
                  }}
                  onClick={() => saveAs(image_focused)}
                >
                  <FiDownload />
                </div>
                <div className="flex flex-grow">
                  <div className="flex mx-auto items-center gap-4">
                    <div
                      className="border rounded-full p-1 cursor-pointer"
                      style={{
                        borderColor: themeColors.primary.base,
                      }}
                      onClick={() => zoomOut()}
                    >
                      <FiMinus />
                    </div>
                    <div className="text-black">Zoom</div>
                    <div
                      className="border rounded-full p-1 cursor-pointer"
                      style={{
                        borderColor: themeColors.primary.base,
                      }}
                      onClick={() => zoomIn()}
                    >
                      <FiPlus />
                    </div>
                  </div>
                </div>
                <div
                  className="border rounded-full p-1 cursor-pointer"
                  style={{
                    borderColor: themeColors.primary.base,
                  }}
                  onClick={() => dispatch(XsetImageFocused(null))}
                >
                  <FiX />
                </div>
              </div>
              <div className="flex items-center flex-grow">
                <TransformComponent>
                  <img src={image_focused} alt="test" />
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default ImgFocused;
