import { forwardRef, useContext, useEffect, useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { ThemeContext } from "../../../../../ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import { XaskBob } from "../../../../../../appSlice";
import AnswerRender from "../../../../components/AnswerRender";

const RelatedQuestionItem = ({ text }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [hovered, setHovered] = useState(false);
  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );
  const dispatch = useDispatch();
  return (
    <div
      style={hovered ? { backgroundColor: themeColors.primary.base } : {}}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`rounded px-1 py-0.5 text-gray-700 hover:text-white hover:font-medium flex items-center cursor-pointer`}
      onClick={() => {
        dispatch(
          XaskBob({
            input: text,
            origin: "similarQuestions",
            from: null,
            userInput: bob_input_value,
          })
        );
      }}
    >
      <div className="text-3xs pr-1 text-gray-300 hover:text-current">
        <BsFillCaretRightFill />
      </div>
      <AnswerRender text={text} />
    </div>
  );
};

const RelatedQuestions = forwardRef(
  (
    {
      related_questions,
      original_question,
      dataArrayIndex,
      needScrollFromMessageOrigin,
      is_from_history,
    },
    olderMessageScrollRef
  ) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useContext(ThemeContext);
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    useEffect(() => {
      needScrollFromMessageOrigin(is_from_history);
    }, []);
    return (
      <div
        className="flex flex-col items-start w-9/12"
        ref={olderMessageScrollRef}
      >
        <div
          className="flex flex-col rounded-tl-sm rounded-lg p-3"
          style={{
            backgroundColor: themeColors.answer_background.base,
            width: "fit-content",
          }}
        >
          <div className="p-2 pt-0 font-medium text-gray-800 whitespace-pre-line">
            {original_question
              ? t("similar-question-original-question") +
                ' \n"' +
                original_question +
                '"'
              : t("similar-question")}
          </div>
          {expanded &&
            related_questions.map((item, i) => (
              <RelatedQuestionItem {...item} key={i} />
            ))}
          {!expanded &&
            related_questions
              .slice(0, 3)
              .map((item, i) => <RelatedQuestionItem {...item} key={i} />)}
          {related_questions?.length > 3 ? (
            <div
              className="px-1 font-medium flex text-gray-700 items-center cursor-pointer ml-auto"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <>
                  <div
                    className="mr-1 text-gray-400 text-3xs"
                    style={{ marginBottom: -3 }}
                  >
                    <BsCaretUpFill />
                  </div>
                  {t("see-less")}
                </>
              ) : (
                <>
                  <div
                    className="mr-1 text-gray-400 text-3xs"
                    style={{ marginBottom: -3 }}
                  >
                    <BsCaretDownFill />
                  </div>
                  {t("see-more")}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
);

export default RelatedQuestions;
