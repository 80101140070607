import { RiQuestionLine, RiChat3Fill, RiAnchorLine } from "react-icons/ri";
import { CgListTree } from "react-icons/cg";
import { MdFace } from "react-icons/md";
import {
  DIMENSIONS,
  LAYOUT,
  PANES,
  IDLE_TIMEOUT,
} from "../../constants/constants";
import IconButton from "./IconButton";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../ThemeProvider";
import {
  XhandleIconButtonClick,
  XsetConversationStarterVisible,
  XsetDisableConversationStarter,
} from "../../appSlice";
import { useContext } from "react";

const SideBar = () => {
  const { t } = useContext(ThemeContext);
  const chat_notification = useSelector((state) => state.app.notification.chat);
  const teacher_notification = useSelector(
    (state) => state.app.notification.teacher
  );
  const pane_visible = useSelector((state) => state.app.general.pane_visible);
  const active_pane = useSelector((state) => state.app.general.active_pane);
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const knowledge_anchoring_visible = useSelector(
    (state) => state.app.general.knowledge_anchoring_visible
  );
  const knowledge_notification = useSelector(
    (state) => state.app.notification.knowledge_anchoring
  );
  const bob_position = useSelector((state) => state.app.general.bob_position);
  const exam_mode = useSelector((state) => state.app.general.exam_mode);
  const dispatch = useDispatch();
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const borderRadius = 22;
  const sideBarBorderRadius = () => {
    if (pane_visible) {
      if (bob_position === LAYOUT.BOB_POSITION.RIGHT) {
        return `0px ${borderRadius}px ${borderRadius}px 0px`;
      }
      if (bob_position === LAYOUT.BOB_POSITION.LEFT) {
        return `${borderRadius}px 0px 0px ${borderRadius}px`;
      }
    }
    return `${borderRadius}px ${borderRadius}px ${borderRadius}px ${borderRadius}px `;
  };

  const handleIconButtonClick = (icon) => {
    if (exam_mode) {
      setTimeout(() => {
        dispatch(XsetDisableConversationStarter(false));
        dispatch(XsetConversationStarterVisible(true));
        setTimeout(() => {
          dispatch(XsetConversationStarterVisible(false));
        }, IDLE_TIMEOUT.IDLE_DISAPPEAR_TIMEOUT);
      }, IDLE_TIMEOUT.IDLE_TIMEOUT);
    } else {
      dispatch(XhandleIconButtonClick({ icon_pane: icon }));
    }
  };

  return (
    <div
      id="SideBar"
      className={`flex flex-col flex-grow items-center p-2 z-10 ${
        external_client_id === "adLz6AKrQw83EC" ? "w-9" : "w-11"
      }`}
      style={{
        backgroundColor: themeColors.primary.base,
        height: DIMENSIONS.SIDEBAR_HEIGHT,
        borderRadius: sideBarBorderRadius(),
      }}
    >
      <div className="flex-1"></div>
      <div className="flex items-center flex-grow">
        <div className="flex flex-col gap-4">
          <IconButton
            id="button_sidebar_chat"
            label="Chat"
            icon={<RiChat3Fill className="text-2xl" />}
            handleClick={() => handleIconButtonClick(PANES.CHAT)}
            active={active_pane === PANES.CHAT}
            badgeValue={chat_notification}
            tooltip={t("tooltip-chat")}
          ></IconButton>
          <IconButton
            id="button_sidebar_teacher"
            label="Teacher Answers"
            icon={<MdFace className="text-2xl" />}
            handleClick={() => handleIconButtonClick(PANES.TEACHER_ANSWERS)}
            active={active_pane === PANES.TEACHER_ANSWERS}
            badgeValue={teacher_notification}
            well
            tooltip={t("tooltip-teacher")}
          ></IconButton>
          {knowledge_anchoring_visible && (
            <IconButton
              id="button_sidebar_ka"
              label="Knowledge Anchoring"
              icon={<RiAnchorLine className="text-2xl" />}
              handleClick={() => handleIconButtonClick(PANES.MEMORY_ANCHORING)}
              active={active_pane === PANES.MEMORY_ANCHORING}
              badgeValue={knowledge_notification}
              tooltip={t("tooltip-KA")}
            ></IconButton>
          )}
          {(external_client_id === "adLz6AKrQw83EC" ||
            external_client_id === "ea5483b7af4ad0") && (
            <IconButton
              id="button_sidebar_al"
              label="Adaptive Learning"
              icon={<CgListTree className="text-xl" />}
              handleClick={() => handleIconButtonClick(PANES.ADAPTIVE_LEARNING)}
              active={active_pane === PANES.ADAPTIVE_LEARNING}
              badge={false}
              badgeValue={false}
              tooltip={t("tooltip-AL")}
            ></IconButton>
          )}
        </div>
      </div>
      <div className="flex items-end flex-1 ">
        {(external_client_id === "469785f61e5c7e" ||
          external_client_id === "ea5483b7af4ad0" ||
          external_client_id === "wI8uqv3BcEh2r5") && (
          <IconButton
            id="button_sidebar_info"
            label="FAQ"
            icon={<RiQuestionLine className="text-xl" />}
            handleClick={() => handleIconButtonClick(PANES.INFO)}
            active={active_pane === PANES.INFO}
            badgeValue={0}
            tooltip={t("tooltip-info")}
          ></IconButton>
        )}
      </div>
    </div>
  );
};

export default SideBar;
