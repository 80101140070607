import { RiChat3Fill, RiAnchorLine } from "react-icons/ri";
import { CgListTree } from "react-icons/cg";
import { MdFace } from "react-icons/md";

import { PANES } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { XhandleIconButtonClick } from "../../../appSlice";

const IconButton = ({
  icon,
  handleClick,
  active,
  badge,
  badgeValue,
  highlight,
}) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  if (highlight) {
    return (
      <div
        className={`rounded-full flex items-center justify-center hover:opacity-100 text-white cursor-pointer ${
          !active && "opacity-40"
        } flex-1`}
        style={{
          width: "24px",
          height: "24px",
        }}
      >
        <div
          className={`flex align-center justify-center relative hover:text-white`}
          onClick={handleClick}
        >
          {/*iconHighlight*/}
          {badge && (
            <div
              style={{
                backgroundColor: themeColors.primary.strong,
              }}
              className={`h-3 w-3 rounded-full flex items-center justify-center text-3xs z-50 absolute left-4 bottom-3 text-white`}
            >
              {badgeValue}
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      className={`flex justify-center align-center relative hover:opacity-100 text-white cursor-pointer ${
        !active && "opacity-40"
      } flex-1`}
      onClick={handleClick}
    >
      <div className="relative flex">
        {icon}
        {badge && (
          <div
            style={{
              backgroundColor: themeColors.primary.strong,
            }}
            className={`h-3 w-3 rounded-full flex items-center justify-center text-3xs z-50 absolute left-4 bottom-3 text-white`}
          >
            {badgeValue}
          </div>
        )}
      </div>
    </div>
  );
};

const MobileMenu = () => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const active_pane = useSelector((state) => state.app.general.active_pane);
  const knowledge_anchoring_visible = useSelector(
    (state) => state.app.general.knowledge_anchoring_visible
  );
  const knowledge_notification = useSelector(
    (state) => state.app.notification.knowledge_anchoring
  );
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const teacher_notification = useSelector(
    (state) => state.app.notification.teacher
  );
  const dispatch = useDispatch();
  return (
    <div
      style={{
        backgroundColor: themeColors.primary.base,
      }}
      className={`flex items-center justify-center py-1 px-20 h-9 text-white font-bold`}
    >
      <IconButton
        label="Chat"
        icon={<RiChat3Fill className="text-2xl" />}
        handleClick={() =>
          dispatch(XhandleIconButtonClick({ icon_pane: PANES.CHAT }))
        }
        active={active_pane === PANES.CHAT}
        badge={false}
        badgeValue={0}
      ></IconButton>
      <IconButton
        label="Teacher Answers"
        icon={<MdFace className="text-2xl" />}
        handleClick={() =>
          dispatch(XhandleIconButtonClick({ icon_pane: PANES.TEACHER_ANSWERS }))
        }
        active={active_pane === PANES.TEACHER_ANSWERS}
        badge={teacher_notification > 0}
        badgeValue={teacher_notification}
        well
      ></IconButton>
      {knowledge_anchoring_visible && (
        <IconButton
          label="Knowledge Anchoring"
          icon={<RiAnchorLine className="text-2xl" />}
          handleClick={() =>
            dispatch(
              XhandleIconButtonClick({ icon_pane: PANES.MEMORY_ANCHORING })
            )
          }
          active={active_pane === PANES.MEMORY_ANCHORING}
          badge={knowledge_notification > 0}
          badgeValue={knowledge_notification}
        ></IconButton>
      )}
      {external_client_id === "adLz6AKrQw83EC" && (
        <IconButton
          label="Adaptive Learning"
          icon={<CgListTree className="text-xl" />}
          handleClick={() =>
            dispatch(
              XhandleIconButtonClick({ icon_pane: PANES.ADAPTIVE_LEARNING })
            )
          }
          active={active_pane === PANES.ADAPTIVE_LEARNING}
          badge={false}
          badgeValue={false}
        ></IconButton>
      )}
    </div>
  );
};

export default MobileMenu;
