const resources = {
  default: {
    translation: {
      // chat
      "bot-out-of-order":"J'ai du mal à répondre à votre question, il semble que le système soit en panne, veuillez réessayer plus tard.",
      "bot-name": "Alan",
      "first-message":
        "Bonjour, je suis Alan. Vous pouvez me poser ici vos questions sur ce cours !",
      "onboarding-message-1":
        "👋 Bonjour, je m'appelle Alan, je suis un assistant virtuel. Vous pouvez me poser ici vos questions sur ce cours !",
      "onboarding-message-2":
        "Posez moi une question ou cliquez sur une des suggestions suivantes:",
      "question-sent":
        "Votre question a bien été envoyée ! Vous recevrez la réponse dans l'onglet 'Réponses du professeur'",
      "question-edit-sent":
        "Si besoin, reformulez votre question, et cliquez sur envoyer",
      "tips-ask-bob":
        "Répondre aux questions de cours est ma spécialité, vous pouvez m'interroger et sélectionner une question parmi les suggestions.",
      no: "Non",
      yes: "Oui",
      or: "ou",
      "question-confirm":
        "Voulez-vous envoyer votre question à un professeur ?",
      "see-less": "voir moins",
      "see-more": "voir plus",
      expand: "Agrandir",
      contract: "Réduire (réponse raccourcie)",
      "explore-source": "Visiter la source",
      // hints:
      "see-more-hints": "(voir plus)",
      // video
      video: "Vidéo",
      loop: "Boucle",
      speed: "vitesse",
      start: "Début",
      end: "Fin",
      "askteacher-aborted":
        "Vous avez abandonné l'envoie d'une question à un Professeur.",
        "ab-test-alert": "Alan change : Choisis ta question parmi l’une de celles affichées ou envoie ta propre question à un professeur du CNED.",
      // App
      "conversation-starter":
        "👋 Bonjour, moi c'est Alan ! \n Vous avez des questions ? Je suis là pour vous aider.",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Alan, mon assistant personnel",
      "pane-header-chat-ASU": "Demande au tuteur ASU!",
      "pane-header-teacher-questions": "Réponses du Professeur",
      "pane-header-memory-anchoring": "Mémorisation",
      "pane-header-settings": "Paramètres",
      "pane-header-info": "FAQ",
      "ask-bob": "Demandez à Alan!",
      "ask-teacher-directly":
        "Posez votre question directement à un Professeur",
      "ask-ASU": "Demande au tuteur ASU!",
      "your-question-for-teacher": "Votre question pour un professeur:",
      //tooltip
      "tooltip-chat-error":
        "👋 Bonjour, moi c'est Alan ! Vous avez des questions ? Je suis là pour vous aider.",
      "tooltip-chat": "Posez vos questions ici",
      "tooltip-teacher-error":
        "Retrouvez ici les questions que vous avez envoyé aux professeurs ainsi que leurs réponses",
      "tooltip-teacher": "Réponses des professeurs",
      "tooltip-KA": "Entrainez vous avec l'ancrage mémoriel",
      "tooltip-AL": "Testez vous avec l'apprentissage adaptatif",
      "tooltip-info-error":
        "Vous avez des questions sur mon fonctionnement ? Les réponses sont ici !",
      "tooltip-info": "Comment ça marche ?",
      // teacher answers
      "mark-unread": "marquer comme non-lu",
      "teacher-answer-new-question": "Vous avez une nouvelle question ? ",
      "teacher-answer-description":
        "Retrouvez ici vos conversations avec les professeurs",
      "respond-to-teacher": "Échange avec les professeurs",
      refresh: "Actualiser",
      you: "Vous: ",
      answer: "Réponse: ",
      reply: "Répondre",
      "conversation-closed":
        "Cette conversation a été close par le professeur, vous pouvez la rouvrir en cliquant ci-dessous",
      // memory anchoring
      "go-to-answer": "Voir la réponse",
      "accept-tcu-1": "J'ai pris connaissance et accepte les ",
      "accept-tcu-2": "termes et conditions",
      "memory-anchoring-3-questions":
        "Quelques questions pour entraîner ta mémoire",
      "memory-anchoring-lets-go": "C'est parti!",
      "memory-anchoring-user-answers-I-knew-it": "Je le savais!",
      "memory-anchoring-user-answers-I-am-learning-it": "Je l'apprends!",
      "memory-anchoring-congratulations": "🎉 Bravo !",
      "memory-anchoring-come-again":
        "Reviens demain pour continuer l’entraînement !",
      "memory-anchoring-come-again-content":
        "Feras-tu aussi bien qu’aujourd’hui ? Plus qu’hier et moins que demain ?",
      "demo-description":
        "Les tests d’ancrage sont envoyés à l’élève à +2, +7, +30 jours après chaque test réussi. En cas d’échec, le cycle est réinitialisé. Après 3 échecs, le prof est alerté.",
      // dashboard
      "dashboard-memory-anchoring-title": "Ancrage Mémoriel",
      "dashboard-memory-anchoring-content":
        "Vérifie tes connaissances avec 3 nouvelles questions!",
      "dashboard-teacher-questions-title": "Réponses Professeurs",
      "dashboard-teacher-questions-content-has-answers":
        "Tu as de nouvelles réponses!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat et pose tes questions aux professeurs!",
      "dashboard-chat-title": "Demandez à Alan!",
      "dashboard-chat-title-asu": "Demande au tuteur ASU!",
      "dashboard-chat-content":
        "Alan est là pour t'aider! Pose lui une question!",
      "dashboard-chat-content-ASU":
        "le tuteur ASU est ici pour t'aider! Pose ta question!",
      // settings
      "bob-language": "Langue Alan:",
      "bob-language-en": "Anglais",
      "bob-language-fr": "Français",
      "settings-position-label": "Position de Alan:",
      "settings-position-right": "Droite",
      "settings-position-left": "Gauche",
      "settings-sidebar-position-label": "Position de la Sidebar:",
      "settings-sidebar-position-center": "Centre",
      "settings-sidebar-position-bottom": "Bas",
      theme: "Thème:",
      "theme-default": "Alan",
      "theme-asu": "ASU",
      "coming-soon": "Bientôt disponible!",
      "welcome-knowledge-anchoring-page":
        "Bienvenue à l'Ancrage de Connaissances ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "Il n'y a pas question pour cette fois.",
      "memory-anchoring-come-again-tomorow": "Reviens demain !",
      "memory-anchoring-description-1":
        "Cette méthode permet de mémoriser plus efficacement et durablement ce que tu apprends. ",
      "memory-anchoring-description-2":
        "Inscris-toi pour recevoir jusqu'à 3 questions par jour qui vont stimuler ton esprit et améliorer la rétention de tes connaissances dans le temps.",
      "enable-email-reminders": "Activer les rappels par email",
      "sign-in": "S'inscrire",
      "is-this-useful": "Cette réponse est-elle utile ?",
      "similar-question": "Voici une liste des questions similaires:",
      "similar-question-original-question":
        "Voici une liste de questions similaires pour votre recherche",
      "bob-found-answer": "Voici la réponse à votre question :",
      "bob-found-similar-question":
        "Voici la question que j'ai sélectionnée et sa réponse associée :",
      "exam-mode":
        "Alan n'est pas disponible lorsque vous êtes en devoir à correction automatisée !",
    },
  },
  ent: {
    translation: {
      // chat
      "bot-out-of-order":"J'ai du mal à répondre à votre question, il semble que le système soit en panne, veuillez réessayer plus tard.",
      "bot-name": "Alan",
      "first-message":
        "Bonjour, je suis Alan. Vous pouvez me poser ici vos questions sur votre scolarité !",
      "onboarding-message-1":
        "👋 Bonjour, je m'appelle Alan, je suis un assistant virtuel. Vous pouvez me poser ici vos questions sur votre scolarité !",
      "onboarding-message-2":
        "Posez moi une question ou cliquez sur une des suggestions suivantes:",
      "question-sent":
        "Votre question a bien été envoyée ! Vous recevrez la réponse dans l'onglet 'Réponses du conseiller'",
      "question-edit-sent":
        "Si besoin, reformulez votre question, et cliquez sur envoyer",
      "tips-ask-bob":
        "Répondre aux questions de cours est ma spécialité, vous pouvez m'interroger et sélectionner une question parmi les suggestions.",
      no: "Non",
      yes: "Oui",
      or: "ou",
      "question-confirm":
        "Voulez-vous envoyer votre question à un conseiller ?",
      "see-less": "voir moins",
      "see-more": "voir plus",
      expand: "Agrandir",
      contract: "Réduire (réponse raccourcie)",
      "explore-source": "Visiter la source",
      // hints:
      "see-more-hints": "(voir plus)",
      // video
      video: "Vidéo",
      loop: "Boucle",
      speed: "vitesse",
      start: "Début",
      end: "Fin",
      "askteacher-aborted":
        "Vous avez abandonné l'envoie d'une question à un Conseiller.",
      
      // App
      "conversation-starter":
        "👋 Bonjour, moi c'est Alan ! \n Vous avez des questions ? Je suis là pour vous aider.",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Alan, mon assistant personnel",
      "pane-header-chat-ASU": "Demande au tuteur ASU!",
      "pane-header-teacher-questions": "Réponses du Conseiller",
      "pane-header-memory-anchoring": "Mémorisation",
      "pane-header-settings": "Paramètres",
      "pane-header-info": "Info",
      "ask-bob": "Demandez à Alan!",
      "ask-teacher-directly":
        "Posez votre question directement à un Conseiller",
      "ask-ASU": "Demande au tuteur ASU!",
      "your-question-for-teacher": "Votre question pour un conseiller:",
      //tooltip
      "tooltip-chat": "Posez vos questions ici",
      "tooltip-teacher-error":
        "Retrouvez ici les questions que vous avez envoyé aux conseillers ainsi que leurs réponses",
      "tooltip-teacher": "Réponses des conseillers",
      "tooltip-KA": "Entrainez vous avec l'ancrage mémoriel",
      "tooltip-AL": "Testez vous avec l'apprentissage adaptatif",
      "tooltip-info-error":
        "Vous avez des questions sur mon fonctionnement ? Les réponses sont ici !",
      "tooltip-info": "Comment ça marche ?",
      // teacher answers
      "mark-unread": "marquer comme non-lu",
      "teacher-answer-new-question": "Vous avez une nouvelle question ? ",
      "teacher-answer-description":
        "Retrouvez ici vos conversations avec les conseillers",
      refresh: "Actualiser",
      "respond-to-teacher": "Échange avec les conseillers",
      you: "Vous: ",
      answer: "Réponse: ",
      reply: "Répondre",
      "conversation-closed":
        "Cette conversation a été close par le conseiller, vous pouvez la rouvrir en cliquant ci-dessous",
      // memory anchoring
      "go-to-answer": "Voir la réponse",
      "accept-tcu-1": "J'ai pris connaissance et accepte les ",
      "accept-tcu-2": "termes et conditions",
      "memory-anchoring-3-questions":
        "Quelques questions pour entraîner ta mémoire",
      "memory-anchoring-lets-go": "C'est parti!",
      "memory-anchoring-user-answers-I-knew-it": "Je le savais!",
      "memory-anchoring-user-answers-I-am-learning-it": "Je l'apprends!",
      "memory-anchoring-congratulations": "🎉 Bravo !",
      "memory-anchoring-come-again":
        "Reviens demain pour continuer l’entraînement !",
      "memory-anchoring-come-again-content":
        "Feras-tu aussi bien qu’aujourd’hui ? Plus qu’hier et moins que demain ?",
      "demo-description":
        "Les tests d’ancrage sont envoyés à l’élève à +2, +7, +30 jours après chaque test réussi. En cas d’échec, le cycle est réinitialisé. Après 3 échecs, le prof est alerté.",
      // dashboard
      "dashboard-memory-anchoring-title": "Ancrage Mémoriel",
      "dashboard-memory-anchoring-content":
        "Vérifie tes connaissances avec 3 nouvelles questions!",
      "dashboard-teacher-questions-title": "Réponses Professeurs",
      "dashboard-teacher-questions-content-has-answers":
        "Tu as de nouvelles réponses!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat et pose tes questions aux professeurs!",
      "dashboard-chat-title": "Demandez à Alan!",
      "dashboard-chat-title-asu": "Demande au tuteur ASU!",
      "dashboard-chat-content":
        "Alan est là pour t'aider! Pose lui une question!",
      "dashboard-chat-content-ASU":
        "le tuteur ASU est ici pour t'aider! Pose ta question!",
      // settings
      "bob-language": "Langue Alan:",
      "bob-language-en": "Anglais",
      "bob-language-fr": "Français",
      "settings-position-label": "Position de Alan:",
      "settings-position-right": "Droite",
      "settings-position-left": "Gauche",
      "settings-sidebar-position-label": "Position de la Sidebar:",
      "settings-sidebar-position-center": "Centre",
      "settings-sidebar-position-bottom": "Bas",
      theme: "Thème:",
      "theme-default": "Alan",
      "theme-asu": "ASU",
      "coming-soon": "Bientôt disponible!",
      "welcome-knowledge-anchoring-page":
        "Bienvenue à l'Ancrage de Connaissances ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "Il n'y a pas question pour cette fois.",
      "memory-anchoring-come-again-tomorow": "Reviens demain !",
      "memory-anchoring-description-1":
        "Cette méthode permet de mémoriser plus efficacement et durablement ce que tu apprends. ",
      "memory-anchoring-description-2":
        "Inscris-toi pour recevoir jusqu'à 3 questions par jour qui vont stimuler ton esprit et améliorer la rétention de tes connaissances dans le temps.",
      "enable-email-reminders": "Activer les rappels par email",
      "sign-in": "S'inscrire",
      "is-this-useful": "Cette réponse est-elle utile ?",
      "similar-question": "Voici une liste des questions similaires:",
      "similar-question-original-question":
        "Voici une liste de questions similaires pour votre recherche",
      "bob-found-answer": "Voici la réponse à votre question :",
      "bob-found-similar-question":
        "Voici la question que j'ai sélectionnée et sa réponse associée : ",
      "exam-mode":
        "Alan n'est pas disponible lorsque vous êtes en devoir à correction automatisée !",
    },
  },
};

export default resources;
