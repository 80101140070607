import HintsItem from "./HintsItem";
import { useSelector } from "react-redux";
const Hints = () => {
  const nb_auto_suggestions = useSelector(
    (state) => state.app.general.nb_auto_suggestions
  );
  const bob_suggestions = useSelector(
    (state) => state.app.chat.bob_suggestions
  );

  return (
    <>
      {bob_suggestions.length > 0 ? (
        <div
          className=" border-t-2 border-gray-400 overflow-auto"
          style={{ maxHeight: "350px" }}
        >
          <div
            className="divide-y divide-gray-100 py-2 "
            style={{ paddingLeft: 7, paddingRight: 7 }}
          >
            {bob_suggestions?.slice(0, nb_auto_suggestions).map((hint, i) => (
              <HintsItem hint={hint} index={i} />
            ))}
          </div>
        </div>
      ) : (
        <div className="border-t-2 border-gray-400"></div>
      )}
    </>
  );
};

export default Hints;
