import { marked } from "marked";

export function text2html(text) {
  let txt;
  if (typeof text === "number") {
    txt = text.toString();
  } else {
    txt = text;
  }
  let html = txt.replace("\\$$", "doubleDollarSelectorNotReapeatable");
  html = html.replace("\\$", "uniqueDollarSelectorNotReapeatable");

  html = html.replace(
    /\${2}([\s\S]+?)\${2}/g,
    '<block-math math="$1"></block-math>'
  );
  html = html.replace(
    /\$([\s\S]+?)\$/g,
    '<inline-math math="$1"></inline-math>'
  );
  html = html.replace("doubleDollarSelectorNotReapeatable", "$$");
  html = html.replace("uniqueDollarSelectorNotReapeatable", "$");
 
  html = marked(html).trim();
  return html;
}

