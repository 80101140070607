import { ThemeContext } from "../../../ThemeProvider";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { FiPlayCircle, FiPauseCircle } from "react-icons/fi";
import { XaskBob } from "../../../../appSlice";

const Onboarding = () => {
  const onboarding_questions = useSelector(
    (state) => state.app.chat.onboarding_questions
  );
  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [isHoverred, setHoverred] = useState(false);

  return (
    <div className="flex h-full font-medium m-auto p-2 w-5/6">
      <div className="flex flex-col m-auto gap-4">
        <div className={`flex flex-col gap-2 text-gray-400`}>
          <div className="m-auto">{t("onboarding-message-1")}</div>
          {external_client_id === "wI8uqv3BcEh2r5" && expanded_pane && (
            <div className="relative bg-gray-100 border-2 px-3 py-4 rounded-lg whitespace-pre-wrap w-full">
              <div
                onMouseEnter={() => setHoverred(true)}
                onMouseLeave={() => setHoverred(false)}
                onClick={() => setVideoPlaying(!isVideoPlaying)}
              >
                <ReactPlayer
                  playing={isVideoPlaying}
                  url={
                    "https://asu-videos-us.s3.us-west-1.amazonaws.com/NEW+Tutorbot+shorter+2.1.mp4"
                  }
                  width="100%"
                  height="100%"
                  playbackRate={0.85}
                />
              </div>

              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white shadow"
                style={{ color: themeColors.primary.base }}
                onMouseEnter={() => setHoverred(true)}
                onClick={() => setVideoPlaying(!isVideoPlaying)}
              >
                {!isVideoPlaying ? (
                  <FiPlayCircle size={50} />
                ) : (
                  isHoverred && <FiPauseCircle size={50} />
                )}
              </div>
              <a
                href="https://asu-videos-us.s3.us-west-1.amazonaws.com/NEW+Tutorbot+shorter+2.1.mp4"
                target="_blank"
                style={{
                  color: themeColors.primary.base,
                }}
                className="absolute bottom-0 right-3 cursor-pointer text-3xs text-gray-400 underline"
              >
                {t("see-in-fullscreen")}
              </a>
            </div>
          )}
          {onboarding_questions.length > 0 && (
            <div className="m-auto">{t("onboarding-message-2")}</div>
          )}
        </div>
        <div className="flex flex-col items-center gap-2 font-normal">
          {onboarding_questions.map((suggestion, index) => (
            <div
            key={index}
              className="text-white w-full text-center p-2 rounded-lg cursor-pointer"
              style={{
                backgroundColor: themeColors.primary.base,
              }}
              onClick={() => {
                dispatch(
                  XaskBob({
                    input: suggestion,
                    origin: "onboarding",
                    from: null,
                    userInput: null,
                  })
                );
              }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
