const resources = {
  default: {
    translation: {
      // chat
      "bot-out-of-order":"J'ai du mal à répondre à ta question, il semble que le système soit en panne, réessaye plus tard.",
      "bot-name":"ESBot",
      "first-message": "Salut ! Je suis ici pour t'aider ! 😄",
      "onboarding-message-1":
        "Je suis ESBot. Je suis ici pour t'aider.",
      "onboarding-message-2":
        "Pose moi une question ou clique sur une des suggestions suivantes:",
      "question-sent": "Ta question a bien été envoyée ! 😄",
      "question-edit-sent": "Soumets ici ta question à un expert :",
      "tips-ask-bob":
        "Répondre aux questions de cours est ma spécialité, tu peux m'interroger et sélectionner une question parmi les suggestions.",
      no: "Non",
      yes: "Oui",
      or: 'ou',
      "question-confirm": "Veux-tu demander à un expert ESBanque ?",
      "see-less": "voir moins",
      "see-more": "voir plus",
      expand: "Agrandir",
      contract: "Réduire (réponse raccourcie)",
      "explore-source": "Visiter la source",
      // hints:
      "see-more-hints": "(voir plus)",
      // video
      video: "Vidéo",
      loop: "Boucle",
      speed: "vitesse",
      start: "Début",
      end: "Fin",
      "askteacher-aborted": "Tu as abandonné l'envoie d'une question à un expert.",
      // App
      "conversation-starter": "👋 Salut! Prêt pour aujourd'hui?",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "ESBot",
      "pane-header-teacher-questions": "Réponses des experts ESB",
      "pane-header-memory-anchoring": "Mémorisation",
      "pane-header-settings": "Paramètres",
      "pane-header-info": "Info",
      "ask-bob": "Pose moi une question",
      "ask-teacher-directly": "Pose ta question directement à un expert",
      "your-question-for-teacher": "Ta question pour un expert:",
      //tooltip
      "tooltip-chat": "Pose moi des question",
      "tooltip-teacher": "Accède aux réponses des experts",
      "tooltip-KA": "Entraine toi avec l'ancrage mémoriel",
      "tooltip-AL": "Teste toi avec l'apprentissage adaptatif",
      "tooltip-info": "Salut, je suis ESBot...",
      // teacher answers
      "mark-unread": "marquer comme non-lu",
      "teacher-answer-new-question": "Tu as une nouvelle question ? ",
      "teacher-answer-description":
        "Retrouve ici tes conversations avec les experts",
      refresh: "Actualiser",
      "respond-to-teacher": "Échange avec les experts",
      you: "Vous: ",
      answer: "Réponse: ",
      reply: "Répondre",
      "conversation-closed":
        "Cette conversation a été close par le expert, tu peux la rouvrir en cliquant ci-dessous",
      // memory anchoring
      "go-to-answer": "Voir la réponse",
      "accept-tcu-1": "J'ai pris connaissance et accepte les ",
      "accept-tcu-2":
        "Conditions Générales d'Utilisation et Informations relatives aux données personnelles",
      "memory-anchoring-3-questions":
        "Quelques questions pour entraîner ta mémoire",
      "memory-anchoring-lets-go": "C'est parti!",
      "memory-anchoring-user-answers-I-knew-it": "Je le savais!",
      "memory-anchoring-user-answers-I-am-learning-it": "Maintenant je sais !",
      "memory-anchoring-congratulations": "🎉 Bravo !",
      "memory-anchoring-come-again":
        "Reviens demain pour continuer l’entraînement !",
      "memory-anchoring-come-again-content":
        "Feras-tu aussi bien qu’aujourd’hui ? Mieux qu’hier et moins que demain ?",
      "demo-description":
        "Les tests d’ancrage sont envoyés à l’élève à +2, +7, +30 jours après chaque test réussi. En cas d’échec, le cycle est réinitialisé. Après 3 échecs, le prof est alerté.",
      // dashboard
      "dashboard-memory-anchoring-title": "Ancrage Mémoriel",
      "dashboard-memory-anchoring-content":
        "Vérifie tes connaissances avec 3 nouvelles questions!",
      "dashboard-teacher-questions-title": "Réponses des experts",
      "dashboard-teacher-questions-content-has-answers":
        "Tu as de nouvelles réponses!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat et pose tes questions aux experts!",
      "dashboard-chat-title": "Demande à ESBot!",
      "dashboard-chat-content":
        "ESBot est là pour t'aider! Pose lui une question!",
      // settings
      "bob-language": "Langue de ESBot:",
      "bob-language-en": "Anglais",
      "bob-language-fr": "Français",
      "settings-position-label": "Position de ESBot :",
      "settings-position-right": "Droite",
      "settings-position-left": "Gauche",
      "settings-sidebar-position-label": "Position de la Sidebar :",
      "settings-sidebar-position-center": "Centre",
      "settings-sidebar-position-bottom": "Bas",
      theme: "Thème:",
      "theme-default": "ESBot",
      "coming-soon": "Bientôt disponible!",
      "welcome-knowledge-anchoring-page":
        "Bienvenue à l'Ancrage de Connaissances ! 😄",
      "you-dont-have-knowledge-anchoring-for-today":
        "Il n'y a pas de question pour cette fois.",
      "memory-anchoring-come-again-tomorow": "A demain !",
      "memory-anchoring-description-1":
        "Cette méthode permet de mémoriser plus efficacement et durablement ce que tu apprends. ",
      "memory-anchoring-description-2":
        "Inscris-toi pour recevoir jusqu'à 3 questions par jour qui vont stimuler ton esprit et améliorer la mémorisation de tes connaissances dans le temps.",
      "enable-email-reminders": "Activer les rappels par email",
      "sign-in": "S'inscrire",
      "is-this-useful": "Cette réponse est-elle utile ?",
      "similar-question": "Voici une liste des questions similaires:",
      "similar-question-original-question":
        "Voici une liste de questions similaires pour votre recherche",
      "bob-found-answer": "J'espère que cela répond à ta question :",
      "bob-found-similar-question":
        "Voici la question que j'ai sélectionnée et sa réponse associée : ",
    },
  },
};

export default resources;
