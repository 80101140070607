import { forwardRef, useContext, useEffect, useState } from "react";
import { BsArrowsAngleContract } from "react-icons/bs";
import { HiOutlineArrowsExpand, HiExternalLink } from "react-icons/hi";
import { LikeOutlined } from "@ant-design/icons";
import AnswerVideo from "./AnswerVideo";
import { ThemeContext } from "../../../../../ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import { XsetExpandedPane, XsetAnswerRate } from "../../../../../../appSlice";
import AnswerRender from "../../../../components/AnswerRender";

const ExpandToFullAnswer = () => {
  const { t } = useContext(ThemeContext);
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const dispatch = useDispatch();
  if (expanded_pane) {
    return (
      <div
        className="flex font-semibold justify-end cursor-pointer pt-1"
        onClick={() => dispatch(XsetExpandedPane(!expanded_pane))}
      >
        <BsArrowsAngleContract />
        {/*<div className="ml-1 text-2xs">{t("contract")}</div>*/}
      </div>
    );
  }
  return (
    <div
      className="justify-end cursor-pointer pt-1"
      onClick={() => dispatch(XsetExpandedPane(!expanded_pane))}
    >
      <div className="flex items-center">
        <div className="mx-1 text-3xs">{t("expand")}</div>
        <HiOutlineArrowsExpand />
      </div>
    </div>
  );
};

const AnswerLabel = ({ answer_id, isIdDisplayed }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );

  return (
    <div
      style={{
        color: themeColors.primary.base,
      }}
      className={`flex-1 mt-1 text-3xs font-medium flex`}
    >
      {false && isIdDisplayed && (
        <div
          style={{
            borderColor: themeColors.primary.base,
          }}
          className={`px-2 py-0 mr-1 border rounded-xl bg-white`}
        >
          {answer_id}
        </div>
      )}
    </div>
  );
};

const ChatMessageItemAnswer = forwardRef(
  (
    {
      needScrollFromMessageOrigin,
      answer,
      dataArrayIndex,
      associated_question,
      original_question,
      is_from_history,
    },
    olderMessageScrollRef
  ) => {
    const iFrame_type = useSelector((state) => state.app.general.iFrame_type);
    const [url, setURL] = useState("");
    const { t } = useContext(ThemeContext);
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    const expanded_pane = useSelector(
      (state) => state.app.general.expanded_pane
    );
    const dev_mode = useSelector((state) => state.app.general.dev_mode);
    const dispatch = useDispatch();
    let referrer;
    try {
      referrer =
        "https://" +
        (document.referrer !== undefined
          ? new URL(document.referrer).hostname
          : "");
    } catch (err) {
      referrer = "";
    }

    const handleRate = (rate) => {
      const rating = rate ? 1 : -1;
      const finalRating = rating === answer.rate ? 0 : rating;
      dispatch(
        XsetAnswerRate({
          rate: finalRating,
          dataArrayIndex: dataArrayIndex,
          id: answer.external_id,
          original_question: original_question,
        })
      );
    };

    useEffect(() => {
      needScrollFromMessageOrigin(is_from_history);
    }, []);

    useEffect(() => {
      try {
        if (answer.uri.startsWith("/")) {
          setURL(referrer + answer.uri);
        } else {
          setURL(answer.uri);
        }
      } catch (err) {}
    }, [answer.uri, referrer]);
    return (
      <div
        className={`bg-white border-4 border-gray-200  rounded-tl-sm rounded-lg px-3 pb-1 w-11/12`}
        ref={olderMessageScrollRef}
      >
        <div className="flex mt-1 mb-2">
          <div className="flex-1">
            <div className="pt-1 font-medium">
              {associated_question === original_question ? (
                t("bob-found-answer")
              ) : (
                <div>
                  <div>{t("bob-found-similar-question")}</div>
                  <div className="font-normal p-1 rounded-md underline">
                    {associated_question}
                  </div>
                </div>
              )}
            </div>
            <AnswerLabel answer_id={answer.id} isIdDisplayed={dev_mode} />
          </div>
          {iFrame_type !== "responsive" && (
            <ExpandToFullAnswer className="flex-1" />
          )}
        </div>

        {/* video */}
        {answer.source_type === "video" && (
          <AnswerVideo
            is_from_history={is_from_history}
            url={answer.uri}
            start_time={
              answer.start_time_in_milliseconds
                ? answer.start_time_in_milliseconds / 1000
                : 0
            }
            start_time_in_milliseconds={answer.start_time_in_milliseconds}
            end_time_in_milliseconds={answer.end_time_in_milliseconds}
            dataArrayIndex={dataArrayIndex}
            needScrollFromMessageOrigin={needScrollFromMessageOrigin}
          />
        )}

        <AnswerRender html={answer.html} text={answer.text} />

        <div className="flex  mt-1">
          {expanded_pane &&
            url !== "" &&
            url !== null &&
            url.lastIndexOf("http", 0) === 0 && (
              <div className="flex-1">
                <a href={url} target="_blank" rel="noreferrer">
                  <div className="video-deco flex items-center mt-1">
                    <div className="text-base" style={{ marginBottom: "2px" }}>
                      <HiExternalLink color="gray" />
                    </div>
                    <div className="ml-1 text-gray-600 font-light">
                      {t("explore-source")}
                    </div>
                  </div>
                </a>
              </div>
            )}
          <div className="flex-1 flex justify-end">
            <div className="flex-none my-auto text-gray-600 font-light">
              {t("is-this-useful")}
            </div>
            <div
              style={
                answer.rate === 1
                  ? {
                      color: themeColors.primary.base,
                    }
                  : {}
              }
              onClick={() => handleRate(true)}
              className={`cursor-pointer text-center pb-2 mx-2 ${
                !answer.rate
                  ? "text-gray-600"
                  : answer.rate === 1
                  ? `font-bold `
                  : "text-gray-300"
              }`}
            >
              <LikeOutlined style={{ fontSize: "15px" }} />
            </div>
            <div
              style={
                answer.rate === -1
                  ? {
                      color: themeColors.primary.base,
                    }
                  : {}
              }
              onClick={() => handleRate(false)}
              className={`cursor-pointer text-center pt-1 ${
                !answer.rate
                  ? "text-gray-600"
                  : answer.rate === -1
                  ? `font-bold `
                  : "text-gray-300"
              }`}
            >
              <LikeOutlined rotate="180" style={{ fontSize: "15px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ChatMessageItemAnswer;
