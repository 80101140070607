import { forwardRef, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../../ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  XnewChatMessage,
  XdisableAskTeacher,
} from "../../../../../../appSlice";

const ChatOfferAskTeacher = forwardRef(
  (
    {
      dataArrayIndex,
      originalQuestion,
      focusOnChatInput,
      timestamp,
      sequence_id,
      needScrollFromMessageOrigin,
      is_from_history,
    },
    olderMessageScrollRef
  ) => {
    const commonClassNames = "flex flex-col rounded-tl-sm rounded-lg p-3";
    const [disabled, setDisabled] = useState(false);
    const [hoveredNO, setHoveredNO] = useState(false);
    const [hoveredYES, setHoveredYES] = useState(false);
    const { t } = useContext(ThemeContext);
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    const external_client = useSelector(
      (state) => state.app.id.external_client
    );

    const dispatch = useDispatch();

    const newChatMessage = (message, user) => {
      dispatch(
        XnewChatMessage({
          answer: null,
          chapterID: null,
          chat: {
            text: message,
            type: "chat",
            original_question: null,
          },
          conversationID: null,
          courseID: null,
          related_questions: null,
          timestamp: new Date(),
          user: {
            userID: user === "user" ? "1" : "0",
            username: null,
          },
        })
      );
    };

    const askTeacherQuestionEditChatMessage = () => {
      dispatch(
        XnewChatMessage({
          answer: null,
          chapterID: null,
          chat: {
            text: null,
            type: "question_edit",
            original_question: originalQuestion,
          },
          sequence_id: sequence_id,
          origin: "chat",
          conversationID: null,
          courseID: null,
          related_questions: null,
          timestamp: new Date(),
          originalTimestamp: timestamp,
          user: {
            userID: "0",
            username: null,
          },
        })
      );
    };

    useEffect(() => {
      needScrollFromMessageOrigin(is_from_history);
    }, []);

    return (
      <div
        className="flex flex-col items-start w-11/12"
        ref={olderMessageScrollRef}
      >
        <div
          style={{
            backgroundColor: themeColors.answer_background.base,
          }}
          className={`${commonClassNames} `}
        >
          {t("question-confirm")}
          {!disabled && (
            <div className="flex pt-2 space-x-3 w-full">
              {external_client === "wI8uqv3BcEh2r5" ? (
                <div
                  style={{
                    color: hoveredNO
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                    borderColor: hoveredNO
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                  }}
                  className={`flex flex-col w-1/2 p-2 items-center text-center rounded-lg bg-white cursor-pointer border-2 font-medium m-auto`}
                  onMouseEnter={() => setHoveredNO(true)}
                  onMouseLeave={() => setHoveredNO(false)}
                  onClick={() => {
                    dispatch(XdisableAskTeacher(dataArrayIndex));
                    newChatMessage("Connect to ASU Tutoring on Zoom", "user");
                    newChatMessage("Ok! 😄", "bob");
                    focusOnChatInput();
                    setDisabled(true);
                    window.open(
                      "https://tutoring.asu.edu/zoom-access",
                      "_blank"
                    );
                  }}
                >
                  Connect to ASU Tutoring on Zoom
                </div>
              ) : (
                <div
                  style={{
                    color: hoveredNO
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                    borderColor: hoveredNO
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                  }}
                  className={`flex flex-col w-1/2 p-2 items-center text-center rounded-lg bg-white cursor-pointer border-2 font-medium m-auto`}
                  onMouseEnter={() => setHoveredNO(true)}
                  onMouseLeave={() => setHoveredNO(false)}
                  onClick={() => {
                    newChatMessage(t("no"), "user");
                    newChatMessage("Ok! 😄", "bob");
                    focusOnChatInput();
                    setDisabled(true);
                  }}
                >
                  {t("no")}
                </div>
              )}
              {external_client === "wI8uqv3BcEh2r5" ? (
                <div
                  style={{
                    backgroundColor: hoveredYES
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                  }}
                  className={`flex flex-col w-1/2 p-2 items-center text-center rounded-lg text-white cursor-pointer font-medium m-auto`}
                  onMouseEnter={() => setHoveredYES(true)}
                  onMouseLeave={() => setHoveredYES(false)}
                  onClick={() => {
                    dispatch(XdisableAskTeacher(dataArrayIndex));
                    newChatMessage("Send a question to an ASU Tutor", "user");
                    askTeacherQuestionEditChatMessage();
                    setDisabled(true);
                  }}
                >
                  Send a question to an ASU Tutor
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: hoveredYES
                      ? themeColors.primary.strong
                      : themeColors.primary.base,
                  }}
                  className={`flex flex-col w-1/2 p-2 items-center text-center rounded-lg text-white cursor-pointer font-medium m-auto`}
                  onMouseEnter={() => setHoveredYES(true)}
                  onMouseLeave={() => setHoveredYES(false)}
                  onClick={() => {
                    dispatch(XdisableAskTeacher(dataArrayIndex));
                    newChatMessage(t("yes"), "user");
                    askTeacherQuestionEditChatMessage();
                    setDisabled(true);
                  }}
                >
                  {t("yes")}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ChatOfferAskTeacher;
