import MarkDownRender from "./MarkDownRender";
import { FiMaximize2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { XsetExpandedPane, XsetImageFocused } from "../../appSlice";

const MarkdownAnswerContent = ({ content }) => {
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const dispatch = useDispatch();
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);

  if (content.substring(0, 5) === "(http") {
    var img = content.substring(content.indexOf("(") + 1, content.indexOf(")"));
    var text = content.substring(content.indexOf(")") + 1);
    return (
      <div>
        <div className="relative group w-3/4 m-auto border-4  rounded-md p-2">
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 text-2xl border-2 rounded-full p-2 cursor-pointer bg-white"
            style={{
              color: themeColors.primary.base,
              borderColor: themeColors.primary.base,
            }}
            onClick={() => {
              dispatch(XsetImageFocused(img));
              dispatch(XsetExpandedPane(true));
            }}
          >
            <FiMaximize2 />
          </div>
          <img src={img} className="" alt={img} />
        </div>

        <div
          className={`prose-xs ${expanded_pane ? "" : "line-clamp-6"}`}
          style={{ overflowX: "auto" }}
        >
          <MarkDownRender source={text} />
        </div>
      </div>
    );
  } else
    return (
      <div
        className={`prose-xs ${expanded_pane ? "" : "line-clamp-6"}`}
        style={{ overflowX: "auto" }}
      >
        <MarkDownRender compact source={content} />
      </div>
    );
};

const MarkdownRenderWithImagesFocus = ({ source }) => {
  if (!source) {
    return "";
  } else
    return source
      .split("![0]")
      .join("![]")
      .split("![]")
      .map((element,key) => <MarkdownAnswerContent content={element} key={key}/>);
};

export default MarkdownRenderWithImagesFocus;
