import { Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiSend } from "react-icons/fi";
import { ThemeContext } from "../../../../ThemeProvider";
import Typewriter from "typewriter-effect";
import Hints from "./components/Hints";
import {
  XsendInputToAutoComplete,
  XsetBobInputValue,
  XresetBobSuggestions,
  XsetABTestDisplayAlert,
  XaskBob,
} from "../../../../../appSlice";

const InputAndHints = ({ chatInputRef }) => {
  const { t } = useContext(ThemeContext);

  const ab_test_send_blocked = useSelector(
    (state) => state.app.ab_test.send_blocked
  );
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );
  const [typeWriter, setTypeWriter] = useState(true);

  const onboarding_questions = useSelector(
    (state) => state.app.chat.onboarding_questions
  );
  const chat_data_array = useSelector(
    (state) => state.app.chat.chat_data_array
  );

  const bob_suggestions = useSelector(
    (state) => state.app.chat.bob_suggestions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(XresetBobSuggestions());
    const timer = setTimeout(() => {
      dispatch(XresetBobSuggestions());
      dispatch(XsendInputToAutoComplete());
    }, 300);
    return () => clearTimeout(timer);
  }, [bob_input_value]);

  useEffect(() => {
    chat_data_array.length > 0 && setTypeWriter(false);
  }, [chat_data_array]);

  const handleSendMessage = () => {
    if (bob_input_value.length > 0 && !ab_test_send_blocked) {
      dispatch(
        XaskBob({
          input: bob_input_value,
          origin: "userInput",
          from: null,
          userInput: bob_input_value,
        })
      );
    } else if (ab_test_send_blocked) {
      dispatch(XsetABTestDisplayAlert(true));
      setTimeout(() => {
        dispatch(XsetABTestDisplayAlert(false));
      }, 3000);
    }
  };

  const handleTypewriter = () => {
    setTypeWriter(false);
  };

  const suffix = () => {
    if (!ab_test_send_blocked) {
      return (
        <FiSend
          style={{
            color: bob_input_value
              ? themeColors.primary.base
              : themeColors.primary.extralight,
          }}
          className="text-base ml-1 -mr-2 cursor-pointer"
          onClick={handleSendMessage}
          id="button_send_chat"
        />
      );
    }
    return <></>;
  };

  return (
    <div id="grid-footer" className="">
      {bob_suggestions?.length > 0 &&
      bob_input_value?.length > 0 &&
      !ab_test_send_blocked ? (
        <Hints />
      ) : (
        <div className=" border-t-2 border-gray-400 overflow-auto"></div>
      )}

      <div
        className="mx-2 mb-1 flex items-center placeholder-white text-base text-gray-400"
        style={{ minHeight: "36px" }}
      >
        {typeWriter && onboarding_questions.length > 0 ? (
          <div
            className="flex w-full cursor-text items-center px-1 m-2"
            onClick={() => handleTypewriter()}
          >
            <Typewriter
              options={{
                strings: onboarding_questions,
                autoStart: true,
                loop: true,
                skipAddStyles: true,
              }}
            />
            <div className="ml-auto mr-2">{suffix}</div>
          </div>
        ) : (
          <Input
            id="input_chat"
            value={bob_input_value}
            placeholder={t("ask-bob")}
            bordered={false}
            allowClear
            onChange={(e) => dispatch(XsetBobInputValue(e.target.value))}
            onPressEnter={handleSendMessage}
            suffix={
              !ab_test_send_blocked && (
                <FiSend
                  style={{
                    color: bob_input_value
                      ? themeColors.primary.base
                      : themeColors.primary.extralight,
                  }}
                  className="text-base ml-1 -mr-2 cursor-pointer"
                  onClick={handleSendMessage}
                  id="button_send_chat"
                />
              )
            }
            className="mr-2"
            autoFocus
            ref={chatInputRef}
            size="large"
            autocomplete="off"
          />
        )}
      </div>
    </div>
  );
};

export default InputAndHints;
